<template>
  <v-footer dark padless>
    <v-card flat tile dark color="accent" class="fill-width">
      <v-container>
        <v-row>
          <v-col :cols="12" :sm="12" :md="4">
            <v-row>
              <v-col>
                <v-img
                  alt="Inlive Logo"
                  class="shrink"
                  src="@/assets/abby-logo.svg"
                  transition="scale-transition"
                  :width="80"
                  contain
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-subtitle-2">
                Abby Online Shopping Private Limited
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-body-2">
                91 Springboard Business Hub Private Limited,
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-body-2">
                1st Floor, Gopala Krishna Complex,
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-body-2"> 45/3, Residency Road,</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-body-2">Bangalore - 560025,</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-body-2">Karnataka,</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-body-2">India</v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col class="text-h6">SITE MAP</v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <router-link
                  :to="{ name: 'Home' }"
                  class="white--text footer-link"
                >
                  Home
                </router-link>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <router-link
                  :to="{ name: 'Upcoming' }"
                  class="white--text footer-link"
                >
                  Upcoming Lives
                </router-link>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <router-link
                  :to="{ name: 'Replays' }"
                  class="white--text footer-link"
                >
                  Replays
                </router-link>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="12" :sm="12" md="auto">
            <v-row dense>
              <v-col>
                <a
                  :href="`mailto:${supportEmail}`"
                  class="contact-link white--text"
                >
                  <div class="px-2">
                    <v-icon small>mdi-email</v-icon>
                    {{ supportEmail }}
                  </div>
                </a>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <a
                  :href="`tel:${contactNumber}`"
                  class="contact-link white--text"
                >
                  <div class="px-2">
                    <v-icon small>mdi-phone</v-icon>
                    {{ contactNumber }}
                  </div>
                </a>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-btn
                  :href="socialMedia.url"
                  target="_blank"
                  icon
                  v-for="socialMedia in socialMedias"
                  :key="`socialMedia-${socialMedia.name}`"
                >
                  <v-icon>{{ socialMedia.icon }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <div class="text-caption">
              Copyright 2022 Abby Online Shopping Private Limited. All rights
              reserved.
            </div>
            <v-spacer></v-spacer>
            <div>
              <router-link
                :to="{ name: 'Privacy' }"
                target="_blank"
                class="white--text text-caption mx-2"
              >
                Privacy
              </router-link>
              <router-link
                :to="{ name: 'TermsConditions' }"
                target="_blank"
                class="white--text text-caption mx-2"
              >
                Terms and Conditions
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "PartnerFooter",
  data: function () {
    return {
      supportEmail: "support@abbyliveshop.com",
      contactNumber: "+91-9833004178",
      socialMedias: [
        {
          name: "instagram",
          icon: "mdi-instagram",
          url: "https://www.instagram.com/abby.liveshopping/",
        },
        {
          name: "facebook",
          icon: "mdi-facebook",
          url: "https://www.facebook.com/abby.liveshopping/",
        },
        {
          name: "youtube",
          icon: "mdi-youtube",
          url: "https://www.youtube.com/channel/UCZlhEl1o3M9qK1InBi5qyxQ",
        },
        {
          name: "linkedin",
          icon: "mdi-linkedin",
          url: "https://www.linkedin.com/company/abbyliveshopping/",
        },
      ],
    };
  },
};
</script>

<style scoped>
.contact-link:link {
  text-decoration: none;
}
.contact-link:visited {
  text-decoration: none;
}
.contact-link:hover {
  text-decoration: none;
}
.contact-link:active {
  text-decoration: none;
}
.footer-link:link {
  text-decoration: none;
}
.footer-link:visited {
  text-decoration: none;
}
.footer-link:hover {
  text-decoration: none;
}
.footer-link:active {
  text-decoration: none;
}
</style>
