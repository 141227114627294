import Vue from "vue";
import Vuetify from "vuetify/lib";
import GoogleIcon from "@/components/icons/GoogleIcon";
import YoutubeIcon from "@/components/icons/YoutubeIcon";
import FacebookIcon from "@/components/icons/FacebookIcon";
import InstagramIcon from "@/components/icons/InstagramIcon";
import AbbyIcon from "@/components/icons/AbbyIcon";
import BannerWidgetIcon from "@/components/icons/widget/BannerWidgetIcon.vue";
import CountdownTimerWidgetIcon from "@/components/icons/widget/CountdownTimerWidgetIcon.vue";
import CouponCodeWidgetIcon from "@/components/icons/widget/CouponCodeWidgetIcon.vue";
import IntermissionScreenWidgetIcon from "@/components/icons/widget/IntermissionScreenWidgetIcon.vue";
import IntroBannerWidgetIcon from "@/components/icons/widget/IntroBannerWidgetIcon.vue";
import IntroScreenWidgetIcon from "@/components/icons/widget/IntroScreenWidgetIcon.vue";
import LogoWidgetIcon from "@/components/icons/widget/LogoWidgetIcon.vue";
import OutroScreenWidgetIcon from "@/components/icons/widget/OutroScreenWidgetIcon.vue";
import OverlayWidgetIcon from "@/components/icons/widget/OverlayWidgetIcon.vue";
import PollsWidgetIcon from "@/components/icons/widget/PollsWidgetIcon.vue";
import ProductCardWidgetIcon from "@/components/icons/widget/ProductCardWidgetIcon.vue";
import QuizWidgetIcon from "@/components/icons/widget/QuizWidgetIcon.vue";
import ProductPosterIcon from "@/components/icons/widget/ProductPosterIcon.vue";
import ImageIcon from "@/components/icons/ImageIcon.vue";
import RotateLeftIcon from "@/components/icons/RotateLeftIcon.vue";
import QuestionMarkCircleOutline from "@/components/icons/widget/QuestionMarkCircleOutline.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#ff4863",
        secondary: "#34c69f",
        accent: "#4cc9f0",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      ampup: {
        primary: "#fe6100",
        secondary: "#0092ca",
        accent: "#cb4e00",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      abby: {
        primary: "#ff4863",
        secondary: "#34c69f",
        accent: "#4cc9f0",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      partner: {
        primary: "#ee0000",
        secondary: "#ffd5d5",
        accent: "#ffd5d5",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  icons: {
    iconfont: "mdi", // default - only for display purposes
    values: {
      abby: {
        component: AbbyIcon,
      },
      google: {
        component: GoogleIcon,
      },
      youtube: {
        component: YoutubeIcon,
      },
      facebook: {
        component: FacebookIcon,
      },
      instagram: {
        component: InstagramIcon,
      },
      banner: {
        component: BannerWidgetIcon,
      },
      "countdown-timer": {
        component: CountdownTimerWidgetIcon,
      },
      "coupon-code": {
        component: CouponCodeWidgetIcon,
      },
      "intermission-screen": {
        component: IntermissionScreenWidgetIcon,
      },
      "intro-banner": {
        component: IntroBannerWidgetIcon,
      },
      "intro-screen": {
        component: IntroScreenWidgetIcon,
      },
      logo: {
        component: LogoWidgetIcon,
      },
      "outro-screen": {
        component: OutroScreenWidgetIcon,
      },
      overlay: {
        component: OverlayWidgetIcon,
      },
      polls: {
        component: PollsWidgetIcon,
      },
      "product-card": {
        component: ProductCardWidgetIcon,
      },
      quiz: {
        component: QuizWidgetIcon,
      },
      "product-poster": {
        component: ProductPosterIcon,
      },
      image: {
        component: ImageIcon,
      },
      rotateLeft: {
        component: RotateLeftIcon,
      },
      questionMarkCircleOutline: {
        component: QuestionMarkCircleOutline,
      },
    },
  },
});
