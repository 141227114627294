import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};
const measurementId =
  process.env.VUE_APP_FIREBASE_GOOGLE_ANALYTICS_MEASUREMENT_ID;
if (measurementId && measurementId.length > 0) {
  firebaseConfig["measurementId"] = measurementId;
}

if (process.env.VUE_APP_DEBUG === "true") {
  console.info(firebaseConfig);
}

const primaryFirebaseApp = initializeApp(firebaseConfig, "primary-auth");
const auth = getAuth(primaryFirebaseApp);
const primaryDB = getFirestore(primaryFirebaseApp);

const chatFirebaseConfig = {
  apiKey: process.env.VUE_APP_CHAT_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_CHAT_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_CHAT_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_CHAT_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_CHAT_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_CHAT_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_CHAT_GOOGLE_ANALYTICS_MEASUREMENT_ID,
};

if (process.env.VUE_APP_DEBUG === "true") {
  console.info(chatFirebaseConfig);
}

const chatFirebaseApp = initializeApp(chatFirebaseConfig, "chat-auth");
const chatAuth = getAuth(chatFirebaseApp);
const chatDB = getFirestore(chatFirebaseApp);

if (process.env.VUE_APP_USE_EMULATOR === "true") {
  auth.useEmulator("http://localhost:9099/");
  chatAuth.useEmulator("http://localhost:9099/");
}

export {
  primaryFirebaseApp,
  chatFirebaseApp,
  auth,
  chatAuth,
  primaryDB,
  chatDB,
};
