import { get as _get, post as _post } from "@/service/apiService";

export async function addUserProfile({ fbUserId, email, dataJson }) {
  return await _post("/users/profile", {
    fbId: fbUserId,
    email,
    dataJson,
  });
}

export async function updateUserProfile(userProfile) {
  return await _post("/users/profile/update", userProfile);
}

export async function getUserProfile() {
  return await _get("/users/profile");
}

export async function canImpersonateUsers() {
  return await _get("/users/profile/impersonate");
}

export async function impersonateOrganization({ organizationNameId }) {
  return await _post("/users/profile/impersonate", { organizationNameId });
}
