<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6846 3.683L13.8641 3.98184C13.4896 4.20687 13.8298 4.77418 14.2048 4.54887L14.3844 4.84771C14.0099 5.07275 14.3501 5.64009 14.7251 5.41474L14.9998 5.8719C14.3763 6.24652 14.1472 7.02774 14.453 7.67355C14.4868 7.74492 14.4667 7.82955 14.399 7.8702L4.27545 13.9531C4.20777 13.9937 4.12358 13.9718 4.07645 13.9085C3.64983 13.3352 2.85255 13.1707 2.22906 13.5454L1.95434 13.0882C2.32887 12.8632 1.98865 12.2958 1.61362 12.5212L1.43406 12.2223C1.80859 11.9973 1.4684 11.43 1.09337 11.6553L0.91381 11.3564C1.28834 11.1314 0.948154 10.5641 0.573124 10.7894L0.296875 10.3297C0.920216 9.9551 1.14934 9.1742 0.84506 8.53058C0.811311 8.4592 0.831436 8.37461 0.899123 8.33395L11.0227 2.25107C11.0904 2.21041 11.1746 2.23235 11.2217 2.29566C11.6472 2.86647 12.4443 3.03078 13.0676 2.65625L13.3439 3.116C12.9693 3.341 13.3095 3.90834 13.6846 3.683Z"
      fill="#FFEAE0"
    />
    <path
      d="M13.0408 7.81943L4.86754 12.7304C4.51535 12.9421 4.05688 12.8277 3.84532 12.4756L2.0037 9.41058C1.79217 9.05852 1.90639 8.59999 2.25858 8.38837L10.4319 3.47736C10.784 3.26573 11.2425 3.38014 11.4541 3.73223L13.2957 6.79722C13.5072 7.14928 13.393 7.60781 13.0408 7.81943ZM2.41201 8.6438C2.20076 8.77074 2.13217 9.04587 2.25911 9.25711L4.10073 12.3221C4.22766 12.5334 4.50279 12.602 4.71404 12.475L12.8873 7.564C13.0986 7.43706 13.1672 7.16194 13.0402 6.95065L11.1986 3.88567C11.0717 3.67439 10.7965 3.60579 10.5853 3.73276L2.41201 8.6438Z"
      fill="#FFD2BD"
    />
    <path
      d="M6.6326 10.0218L6.78613 10.2773L11.8977 7.20639L11.7441 6.95084L6.6326 10.0218Z"
      fill="#FFD2BD"
    />
    <path
      d="M11.382 0.874341L11.6286 1.12087C11.3196 1.42984 11.787 1.89799 12.0963 1.58862L12.3429 1.83515C12.0339 2.14412 12.5012 2.61227 12.8106 2.3029L13.1878 2.68005C12.6734 3.19439 12.6401 4.0078 13.0931 4.56045C13.1432 4.62151 13.1441 4.70851 13.0883 4.76436L4.73698 13.1157C4.68114 13.1715 4.59417 13.1706 4.53308 13.1205C3.98045 12.6675 3.16705 12.7008 2.65271 13.2151L2.27555 12.838C2.58452 12.529 2.11718 12.0608 1.80781 12.3702L1.56128 12.1237C1.87024 11.8147 1.4029 11.3466 1.09353 11.6559L0.846997 11.4094C1.15596 11.1004 0.688622 10.6323 0.379249 10.9416L0 10.5624C0.514217 10.0482 0.547592 9.23503 0.0966559 8.68415C0.0466561 8.62306 0.0457186 8.53609 0.101531 8.48028L8.45287 0.128906C8.50869 0.0730936 8.59565 0.0740311 8.65675 0.124031C9.20762 0.574967 10.0208 0.541561 10.535 0.0273438L10.9143 0.406592C10.6053 0.71556 11.0727 1.18371 11.382 0.874341Z"
      fill="#FFD2BD"
    />
    <path
      d="M11.757 5.04197L5.0146 11.7844C4.72407 12.0749 4.25154 12.0749 3.9611 11.7844L1.43271 9.25599C1.14227 8.96555 1.14218 8.49302 1.43271 8.20249L8.17512 1.46005C8.46565 1.16952 8.93818 1.16961 9.22861 1.46005L11.757 3.98848C12.0474 4.27891 12.0475 4.75144 11.757 5.04197ZM1.64339 8.41318C1.46911 8.58746 1.46911 8.87099 1.64339 9.04527L4.17179 11.5737C4.34607 11.748 4.6296 11.748 4.80388 11.5737L11.5463 4.83126C11.7206 4.65698 11.7206 4.37345 11.5463 4.19916L9.0179 1.67074C8.84362 1.49646 8.56009 1.49646 8.3858 1.67074L1.64339 8.41318Z"
      fill="#FF793A"
    />
    <path
      d="M2.82826 7.4806L3.03906 7.69141L3.24987 7.4806L3.03907 7.2698L2.82826 7.4806Z"
      fill="#FF793A"
    />
    <path
      d="M3.27748 7.92982L3.48828 8.14062L3.69909 7.92982L3.48829 7.71901L3.27748 7.92982Z"
      fill="#FF793A"
    />
    <path
      d="M3.7267 8.37903L3.9375 8.58984L4.14831 8.37904L3.93751 8.16823L3.7267 8.37903Z"
      fill="#FF793A"
    />
    <path
      d="M4.17641 8.83216L4.38721 9.04297L4.59802 8.83217L4.38721 8.62136L4.17641 8.83216Z"
      fill="#FF793A"
    />
    <path
      d="M4.62611 9.28138L4.83691 9.49219L5.04772 9.28139L4.83692 9.07058L4.62611 9.28138Z"
      fill="#FF793A"
    />
    <path
      d="M5.07484 9.7306L5.28564 9.94141L5.49645 9.7306L5.28565 9.5198L5.07484 9.7306Z"
      fill="#FF793A"
    />
    <path
      d="M5.52455 10.1798L5.73535 10.3906L5.94616 10.1798L5.73536 9.96901L5.52455 10.1798Z"
      fill="#FF793A"
    />
    <path
      d="M4.27504 6.93372L4.48584 7.14453L8.70246 2.92806L8.49166 2.71725L4.27504 6.93372Z"
      fill="#FF793A"
    />
    <path
      d="M6.0724 8.7306L6.2832 8.94141L10.4998 4.72493L10.289 4.51412L6.0724 8.7306Z"
      fill="#FF793A"
    />
  </svg>
</template>

<script>
export default {
  name: "CouponCodeWidgetIcon",
};
</script>

<style scoped></style>
