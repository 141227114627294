<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C3.58149 0 0 3.58149 0 8C0 12.4185 3.58149 16 8 16C12.4185 16 16 12.4185 16 8C16 3.58149 12.4185 0 8 0ZM8 14.769C4.26818 14.769 1.23097 11.7321 1.23097 8C1.23097 4.26785 4.26841 1.23097 8 1.23097C11.7321 1.23097 14.769 4.26789 14.769 8C14.769 11.7321 11.7322 14.769 8 14.769Z"
      fill="#FFD2BD"
    />
    <path
      d="M5.53809 4.94141H7.38421V11.0953H5.53809V4.94141Z"
      fill="#FF793A"
    />
    <path
      d="M8.61475 4.94141H10.4609V11.0953H8.61475V4.94141Z"
      fill="#FF793A"
    />
  </svg>
</template>

<script>
export default {
  name: "IntermissionScreenWidgetIcon",
};
</script>

<style scoped></style>
