<template>
  <v-app-bar :height="height" elevation="1" app>
    <app-bar-logo :homeRoute="{ name: 'Home' }"></app-bar-logo>
  </v-app-bar>
</template>

<script>
import AppBarLogo from "../logo/AppBarLogo.vue";
export default {
  components: { AppBarLogo },
  name: "WebsiteAppBar",
  data: function () {
    return {
      height: 70,
    };
  },
};
</script>

<style scoped></style>
