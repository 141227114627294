import vuetify from "@/plugins/vuetify";

const state = () => ({
  loading: false,
  showError: false,
  errorMessage: null,
  showSuccess: false,
  successMessage: "",
  showNavIcon: false,
  showNavigationDrawer: false,
  systemMessage: null,
  isDirty: false,
  locale: {
    name: "en-IN",
    country: "IN",
  },
  layout: "loader",
  theme: "web",
  useImageProxy: false,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  SET_LOADING: function (state, value) {
    state.loading = value;
  },
  SET_SHOW_ERROR: function (state, { value, message }) {
    state.showError = value;
    state.errorMessage = message;
  },
  SET_SHOW_SUCCESS: function (state, { value, message }) {
    state.showSuccess = value;
    state.successMessage = message;
  },
  SET_SHOW_NAV_ICON: function (state, value) {
    state.showNavIcon = value;
  },
  SET_SHOW_NAVIGATION_DRAWER: function (state, value) {
    state.showNavigationDrawer = value;
  },
  TOGGLE_NAVIGATION_DRAWER: function (state) {
    state.showNavigationDrawer = !state.showNavigationDrawer;
  },
  SET_SYSTEM_MESSAGE(state, systemMessage) {
    state.systemMessage = systemMessage;
  },
  SET_DIRTY_FLAG(state, isDirty) {
    state.isDirty = isDirty;
  },
  SET_LAYOUT(state, layout) {
    state.layout = layout;
  },
  SET_THEME(state, theme) {
    state.theme = theme;
    vuetify.framework.theme.themes.light =
      vuetify.userPreset.theme.themes[theme];
  },
  SET_USE_IMAGE_PROXY(state, value) {
    state.useImageProxy = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
