import { getAllOauths as _getAllOauths } from "@/service/oauthService";
import Vue from "vue";

const state = () => ({
  oauthsDict: {},
});

// getters
const getters = {
  oauths: (state) => {
    return Object.values(state.oauthsDict);
  },
};

// actions
const actions = {
  async initOauths({ commit }) {
    const oauths = await _getAllOauths();
    for (let oauth of oauths) {
      commit("SET_OAUTH", oauth);
    }
  },
};

// mutations
const mutations = {
  SET_OAUTH: function (state, oauth) {
    Vue.set(state.oauthsDict, oauth.socialNetworkId, oauth);
  },
  REMOVE_OAUTH: function (state, oauth) {
    Vue.set(state.oauthsDict, oauth.socialNetworkId, null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
