<template>
  <div class="fill-height">
    <website-app-bar></website-app-bar>
    <v-main>
      <div class="fill-height">
        <v-container fill-height>
          <v-row class="fill-height">
            <v-col>
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
    <website-footer class="mt-4"></website-footer>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import WebsiteAppBar from "@/components/website/WebsiteAppBar.vue";
import WebsiteFooter from "@/components/website/WebsiteFooter.vue";
export default {
  components: { WebsiteAppBar, WebsiteFooter },
  name: "WebsiteLayout",
  created: function () {
    this.setTheme("abby");
  },
  methods: {
    ...mapMutations("core", { setTheme: "SET_THEME" }),
  },
};
</script>

<style scoped></style>
