<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.5 0.5H6.5V6.5H0.5V0.5Z" fill="white" stroke="white" />
    <path d="M7 0H14V7H7V0Z" fill="#FF793A" />
    <path
      d="M0.25 7.25H7.75V13.75H0.25V7.25Z"
      fill="white"
      stroke="#FFD2BD"
      stroke-width="0.5"
    />
    <path
      d="M7.25 7.25H13.75V13.75H7.25V7.25Z"
      fill="white"
      stroke="#FFAF89"
      stroke-width="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: "LogoWidgetIcon",
};
</script>

<style scoped></style>
