<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M17.0585 6.25862C16.7282 6.25862 16.4593 5.98836 16.4549 5.65201V4.76669C16.4656 4.0152 16.1823 3.29026 15.6674 2.75195C15.1524 2.21363 14.4483 1.90622 13.7105 1.89758H13.0667C12.7333 1.89758 12.4631 1.62233 12.4631 1.28278C12.4631 0.943228 12.7333 0.667969 13.0667 0.667969H13.7105C15.914 0.694904 17.6864 2.5221 17.6862 4.76669V5.64382C17.6906 5.80535 17.6306 5.9618 17.52 6.0776C17.4093 6.19339 17.2574 6.25868 17.0987 6.25862H17.0585ZM2.9745 6.25868C2.81516 6.26082 2.6616 6.19788 2.54817 6.08388C2.43473 5.96988 2.37088 5.81433 2.3709 5.65201V4.76669C2.35799 3.68419 2.77115 2.64149 3.51815 1.87139C4.26514 1.10128 5.28376 0.667889 6.34661 0.667969H7.00654C7.3399 0.667969 7.61014 0.943228 7.61014 1.28278C7.61014 1.62233 7.3399 1.89758 7.00654 1.89758H6.35466C5.61056 1.89527 4.89688 2.19814 4.37452 2.7379C3.85217 3.27767 3.56512 4.00889 3.5781 4.76669V5.65201C3.5737 5.98836 3.30474 6.25868 2.9745 6.25868Z"
      fill="#FF793A"
    />
    <path
      d="M17.6619 9.89026V12.1937C17.6361 14.4097 15.8857 16.2035 13.7103 16.2433H13.0584C12.8276 16.2706 12.6019 16.1605 12.4781 15.9602C12.3543 15.76 12.3543 15.5051 12.4781 15.3049C12.6019 15.1047 12.8276 14.9946 13.0584 15.0219H13.7103C15.245 15.0173 16.4869 13.7488 16.4868 12.1855V9.89026H17.6619ZM7.60991 15.6367C7.60557 15.9744 7.33786 16.2471 7.00631 16.2515H6.34637C4.14286 16.2245 2.3705 14.3973 2.37066 12.1528V9.89026H3.57786V12.1937C3.57784 13.7538 4.81477 15.021 6.34637 15.0301H7.00631C7.16565 15.0279 7.31921 15.0908 7.43264 15.2048C7.54607 15.3188 7.60991 15.4744 7.60991 15.6367ZM19.1668 9.27546C19.1668 9.61501 18.8966 9.89026 18.5632 9.89026H1.4371C1.10374 9.89026 0.833496 9.61501 0.833496 9.27546C0.833496 8.93591 1.10374 8.66065 1.4371 8.66065H18.5391C18.7033 8.65395 18.8631 8.71576 18.9816 8.83179C19.1001 8.94782 19.1668 9.10805 19.1668 9.27546Z"
      fill="#FF793A"
    />
    <path
      opacity="0.4"
      d="M14.1931 7.17527V6.1178C14.1931 5.60689 13.9939 5.1169 13.6392 4.75564C13.2845 4.39437 12.8035 4.19141 12.3019 4.19141H7.69841C6.65388 4.19141 5.80713 5.05388 5.80713 6.1178V7.17527C5.81129 7.30024 5.90978 7.40056 6.03247 7.4048H13.9678C14.0905 7.40056 14.189 7.30024 14.1931 7.17527Z"
      fill="#FF793A"
    />
    <path
      d="M14.1931 9.89062V10.8087C14.1931 11.8727 13.3464 12.7351 12.3019 12.7351H7.69841C6.65388 12.7351 5.80713 11.8727 5.80713 10.8087V9.89062H14.1931Z"
      fill="#FF793A"
    />
  </svg>
</template>

<script>
export default {
  name: "OverlayWidgetIcon",
};
</script>

<style scoped></style>
