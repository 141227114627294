<template>
  <v-app-bar :height="height" elevation="0" app>
    <app-bar-logo :homeRoute="{ name: 'Home' }" class="mr-14"></app-bar-logo>
    <v-btn text :to="{ name: 'Home' }" class="mx-1" exact>
      What is Live Commerce
    </v-btn>
    <v-btn text :to="{ name: 'Home' }" class="mx-1" exact> How it works </v-btn>
    <v-btn text :to="{ name: 'Home' }" class="mx-1" exact> Shows </v-btn>
    <v-btn text :to="{ name: 'Home' }" class="mx-1" exact> Abby Academy </v-btn>
    <v-spacer></v-spacer>
    <v-btn color="primary"> Join waitlist </v-btn>
  </v-app-bar>
</template>

<script>
import AppBarLogo from "../logo/AppBarLogo.vue";
export default {
  components: { AppBarLogo },
  name: "PartnerAppBar",
  data: function () {
    return {
      height: 70,
    };
  },
};
</script>

<style scoped></style>
