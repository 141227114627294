import {
  signInAnonymously as _signInAnonymously,
  reload as _reload,
  updateProfile as _updateProfile,
  signOut as _signOut,
} from "firebase/auth";
import { chatAuth as auth } from "@/firebase";
import {
  updateUserName as _updateUserName,
  subscribe as _subscribe,
  unsubscribe as _unsubscribe,
  sendMessage as _sendMessage,
  listenMessages as _listenMessages,
  getUser as _getUser,
  addRole as _addRole,
  createUser as _createUser,
  listenChannels as _listenChannels,
  removeMessage as _removeMessage,
  muteUser as _muteUser,
  unmuteUser as _unmuteUser,
  getDummyMessage as _getDummyMessage,
} from "@/service/chatService";
import Vue from "vue";

const state = () => ({
  fbUser: null,
  fbUserAttributes: null,
  chatUser: null,
  channelDict: {},
  channelUnsubscribeFuncDict: {},
  messagesDict: {},
  messageUnsubscribeFunc: null,
});

// getters
const getters = {
  fbId: (state) => {
    return state.fbUserAttributes?.userId;
  },
  isAuthenticated: (state) => {
    return state.fbUserAttributes != null;
  },
  name: (state) => {
    return state.fbUserAttributes?.name;
  },
  isModerator: (state) => {
    return ["admin", "chat_moderator"].includes(state.chatUser?.role);
  },
  validMessages: (state) => {
    const messageList = Object.values(state.messagesDict);
    return messageList.filter((message) => !message.removedAt);
  },
  messages: (state, getters) => {
    return [...getters.validMessages].sort(
      (a, b) => a.timestamp.seconds - b.timestamp.seconds
    );
  },
  messagesForModeration: (state, getters) => {
    return [...getters.validMessages].sort(
      (a, b) => b.timestamp.seconds - a.timestamp.seconds
    );
  },
  getChannel:
    (state) =>
    ({ channelId }) => {
      return state.channelDict[channelId];
    },
  isUserMutedForChannel:
    (state, getters) =>
    ({ channelId }) => {
      const channel = getters.getChannel({ channelId });
      if (channel) {
        return channel.muted.includes(getters.fbId);
      }
      return false;
    },
};

// actions
const actions = {
  async signInAnonymously({ dispatch }) {
    let loginSuccess = true;
    try {
      const { user } = await _signInAnonymously(auth);
      await _createUser({ userId: user.uid });
      await dispatch("updateUser", user);
    } catch (error) {
      const authErrors = [];
      loginSuccess = false;
      if (!authErrors.includes(error.code)) {
        throw error;
      }
    }
    return { loginSuccess };
  },
  async updateUserName({ state, getters, dispatch }, { userName }) {
    await _updateProfile(state.fbUser, {
      displayName: userName,
    });
    await _updateUserName({ userId: getters.fbId, userName });
    dispatch("reloadUser");
  },
  async checkAndAddModerator({ getters, dispatch }, { userName }) {
    if (!getters.isAuthenticated) {
      await dispatch("signInAnonymously");
      await dispatch("updateUserName", { userName });
    }
    if (!getters.isModerator) {
      await _addRole({ userId: getters.fbId, role: "chat_moderator" });
      dispatch("reloadUser");
    }
  },
  async reloadUser({ dispatch }) {
    await _reload(auth.currentUser);
    await dispatch("updateUser", auth.currentUser);
  },
  async signOut() {
    await _signOut(auth);
  },
  async updateUser({ commit }, fbUser) {
    if (fbUser != null) {
      const chatUser = await _getUser(fbUser.uid);
      commit("SET_USER", { fbUser, chatUser });
    } else {
      commit("RESET_USER");
    }
  },
  async subscribe({ getters, commit }, { channelId, autoload }) {
    const channel = await _subscribe({ channelId, userId: getters.fbId });
    commit("SET_CHANNEL", channel);
    const channelListener = ({ channel }) => {
      commit("SET_CHANNEL", channel);
    };
    const channelUnsubscribeFunc = await _listenChannels({
      channelId,
      userId: getters.fbId,
      listener: channelListener,
    });
    commit("SET_CHANNEL_UNSUBSCRIBE_FUNC", {
      channelId,
      unsubscribeFunc: channelUnsubscribeFunc,
    });
    const listener = ({ message }) => {
      commit("ADD_MESSAGES", [message]);
    };
    const { messages, unsubscribeFunc } = await _listenMessages({
      channelId,
      autoload,
      listener,
    });
    commit("ADD_MESSAGES", messages);
    commit("SET_MESSAGE_UNSUBSCRIBE_FUNC", unsubscribeFunc);
  },
  async unsubscribe({ state, getters }, { channelId }) {
    const channelUnsubscribeFunc = state.channelUnsubscribeFuncDict[channelId];
    if (channelUnsubscribeFunc) {
      channelUnsubscribeFunc();
    }
    state.messageUnsubscribeFunc();
    await _unsubscribe({ channelId, userId: getters.fbId });
  },
  async sendMessage({ getters, commit }, { channelId, message }) {
    const isUserMuted = getters.isUserMutedForChannel({ channelId });
    if (isUserMuted) {
      const dummyMessage = await _getDummyMessage({
        senderId: getters.fbId,
        senderName: getters.name,
        message: {
          text: message,
        },
      });
      commit("ADD_MESSAGES", [dummyMessage]);
    } else {
      await _sendMessage({
        senderId: getters.fbId,
        senderName: getters.name,
        channelId,
        message: {
          text: message,
        },
      });
    }
  },
  async removeMessage(context, { channelId, messageId }) {
    await _removeMessage({ channelId, messageId });
  },
  async muteUser(context, { channelId, userId }) {
    await _muteUser({ channelId, userId });
  },
  async unmuteUser(context, { channelId, userId }) {
    await _unmuteUser({ channelId, userId });
  },
};

// mutations
const mutations = {
  SET_USER(state, { fbUser, chatUser }) {
    state.fbUser = fbUser;
    state.fbUserAttributes = {
      userId: fbUser.uid,
      isAuthenticated: true,
      name: fbUser.displayName,
    };
    state.chatUser = chatUser;
  },
  RESET_USER(state) {
    state.fbUser = null;
    state.fbUserAttributes = null;
    state.chatUser = null;
  },
  SET_CHANNEL(state, channel) {
    Vue.set(state.channelDict, channel.id, channel);
  },
  SET_CHANNEL_UNSUBSCRIBE_FUNC(state, { channelId, unsubscribeFunc }) {
    Vue.set(state.channelUnsubscribeFuncDict, channelId, unsubscribeFunc);
  },
  ADD_MESSAGES(state, messages) {
    for (let message of messages) {
      Vue.set(state.messagesDict, message.id, message);
    }
  },
  SET_MESSAGE_UNSUBSCRIBE_FUNC(state, func) {
    state.messageUnsubscribeFunc = func;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
