import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import {
  initFacebook as _initFacebook,
  loadFacebookSDK as _loadFacebookSDK,
} from "@/facebook";
import vuetify from "@/plugins/vuetify";
import {
  format as _dateFormat,
  parseISO as _parseISO,
  parse as _parseDate,
  isValid as _isValidDate,
} from "date-fns";
import { auth, chatAuth } from "@/firebase";
import i18n from "@/i18n";
import "@/assets/css/main.css";
import { calculateTimeDifference as _calculateTimeDifference } from "@/utils/timeUtils";
import { getImageUrl as _getImageUrl } from "@/utils/assetUtils";
import { required, email, min, max } from "vee-validate/dist/rules";
import {
  extend as _vee_validate_extend,
  setInteractionMode as _setInteractionMode,
} from "vee-validate";
import VueObserveVisibility from "vue-observe-visibility";
import DatetimePicker from "vuetify-datetime-picker";
import VueClipboard from "vue-clipboard2";
import Vue2Filters from "vue2-filters";
import VueAnalytics from "vue-analytics";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.config.performance = false;

Vue.config.errorHandler = function (error) {
  if (!(error.response && [401, 403, 404].includes(error.response.status))) {
    // if (process.env.VUE_APP_DEBUG === "true") {
    console.error(error);
    // }
    store.commit("core/SET_SHOW_ERROR", { value: true }, { root: true });
  }
};

Vue.use(VueObserveVisibility);
Vue.use(DatetimePicker);
Vue.use(VueClipboard);
Vue.use(Vue2Filters);

if (process.env.VUE_APP_ENV == "prod") {
  if (process.env.VUE_APP_WEBSITE_GOOGLE_ANALYTICS_MODE == "ua") {
    Vue.use(VueAnalytics, {
      id: process.env.VUE_APP_WEBSITE_GOOGLE_ANALYTICS_UA_ID,
      router,
    });
  } else if (process.env.VUE_APP_WEBSITE_GOOGLE_ANALYTICS_MODE == "ga4") {
    Vue.use(
      VueGtag,
      {
        config: {
          id: process.env.VUE_APP_WEBSITE_GOOGLE_ANALYTICS_MEASUREMENT_ID,
        },
      },
      router
    );
  }
}

Vue.filter("formatMonthDateString", function (value) {
  if (value) {
    return _dateFormat(_parseISO(value), "MMMM yyyy");
  }
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return _dateFormat(value, "MMM do, yyyy");
  }
});

Vue.filter("formatDateString", function (value) {
  if (value) {
    return _dateFormat(_parseISO(value), "MMM do, yyyy");
  }
});

Vue.filter("formatTime", function (value) {
  if (value) {
    return _dateFormat(value, "h:mm a");
  }
});

Vue.filter("formatTimeString", function (value) {
  if (value) {
    return _dateFormat(_parseISO(value), "h:mm a");
  }
});

Vue.filter("formatDateTime", function (value) {
  if (value) {
    return _dateFormat(value, "MMM do, yyyy h:mm a");
  }
});

Vue.filter("formatDateTimeString", function (value) {
  if (value) {
    return _dateFormat(_parseISO(value), "MMM do, yyyy h:mm a");
  }
});

Vue.filter("formatDayMonthTimeString", function (value) {
  if (value) {
    return _dateFormat(_parseISO(value), "MMM do h:mm a");
  }
});

Vue.filter("timeLapsed", function (value) {
  return _calculateTimeDifference(value, new Date());
});

Vue.filter("timeLapsedShort", function (value) {
  return _calculateTimeDifference(value, new Date(), true);
});

Vue.filter("ellipsis", function (value, maxChar, useWordBoundary) {
  if (value.length <= maxChar) {
    return value;
  }
  const subString = value.substr(0, maxChar - 1);
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
});

Vue.filter("emptyToDash", function (value) {
  return value && value.trim().length > 0 ? value : "-";
});

Vue.filter("imageUrl", function (value) {
  return _getImageUrl(value);
});

Vue.filter("doubleDigit", function (value) {
  return value < 10 ? `0${value}` : `${value}`;
});

_setInteractionMode("eager");

_vee_validate_extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

_vee_validate_extend("min", {
  ...min,
  message: "{_field_} should be atleast {length} characters",
});

_vee_validate_extend("max", {
  ...max,
  message: "{_field_} cannot be more than {length} characters",
});

_vee_validate_extend("email", {
  ...email,
  message: "{_field_} must be valid",
});

_vee_validate_extend("date", {
  message: "{_field_} is not a valid date",
  validate: (value) => {
    const date = _parseDate(value, "yyyy-M-d", new Date());
    return _isValidDate(date);
  },
});

let app;
auth.onAuthStateChanged(async (user) => {
  await store.dispatch("user/updateUser", user);
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  }
});

chatAuth.onAuthStateChanged(async (user) => {
  await store.dispatch("chat/updateUser", user);
});

_initFacebook();
_loadFacebookSDK(document, "script", "facebook-jssdk");
