<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.91406 6.80078V11.3741"
      stroke="#FFD2BD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.02539 4.61328V11.3753"
      stroke="#FFD2BD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.0859 9.21484V11.3715"
      stroke="#FFD2BD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.124 1.33203H4.87635C2.69858 1.33203 1.3335 2.87342 1.3335 5.05547V10.9419C1.3335 13.124 2.69223 14.6654 4.87635 14.6654H11.124C13.3081 14.6654 14.6668 13.124 14.6668 10.9419V5.05547C14.6668 2.87342 13.3081 1.33203 11.124 1.33203Z"
      stroke="#FF793A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "PollsWidgetIcon",
};
</script>

<style scoped></style>
