import { compare as _compare } from "@/utils/arrayUtils";
export function loadFacebookSDK(root, scriptTag, id) {
  let js,
    fjs = root.getElementsByTagName(scriptTag)[0];
  if (root.getElementById(id)) {
    return;
  }
  js = root.createElement(scriptTag);
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
}

export function initFacebook() {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      version: "v15.0",
      xfbml: false,
    });
  };
}

export async function connect({ permissions }) {
  let { authResponse } = await getLoginStatus();
  if (!authResponse) {
    ({ authResponse } = await askForPermissions({ permissions }));
  } else {
    const authResponseList = authResponse.grantedScopes.split(",");
    if (!_compare(permissions, authResponseList)) {
      const missingPermissions = permissions
        .sort()
        .filter((x) => !authResponseList.sort().includes(x));
      ({ authResponse } = await askForPermissions({
        permissions: missingPermissions,
      }));
    }
  }
  const response = {
    authResponse,
  };
  if (authResponse) {
    response.permissions = await getPermissions();
  }
  return response;
}

export async function disconnect() {
  return new Promise((resolve) => {
    window.FB.api("/me/permissions", "DELETE", function (response) {
      resolve(response);
    });
  });
}

export function getPermissions() {
  return new Promise((resolve) => {
    window.FB.api("/me/permissions", function (response) {
      resolve(response);
    });
  });
}

export function getLoginStatus() {
  return new Promise((resolve) => {
    window.FB.getLoginStatus(function (response) {
      resolve(response);
    });
  });
}

export function askForPermissions({ permissions }) {
  return new Promise((resolve) => {
    const commaSeparatedPermissions = permissions.join(",");
    window.FB.login(
      function (response) {
        resolve(response);
      },
      {
        scope: commaSeparatedPermissions,
        return_scopes: true,
      }
    );
  });
}
