<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1232_80763)">
      <path
        d="M7.99999 3.83464V1.16797L4.66666 4.5013L7.99999 7.83463V5.16797C10.2067 5.16797 12 6.9613 12 9.16797C12 11.3746 10.2067 13.168 7.99999 13.168C5.79332 13.168 3.99999 11.3746 3.99999 9.16797H2.66666C2.66666 12.1146 5.05332 14.5013 7.99999 14.5013C10.9467 14.5013 13.3333 12.1146 13.3333 9.16797C13.3333 6.2213 10.9467 3.83464 7.99999 3.83464Z"
        fill="#FF793A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1232_80763">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "RotateLeftIcon",
};
</script>

<style scoped></style>
