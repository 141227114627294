import {
  getAllProducts as _getAllProducts,
  addProduct as _addProduct,
  updateProduct as _updateProduct,
  deleteProduct as _deleteProduct,
} from "@/service/productService";

const state = () => ({
  products: [],
  isInitialized: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  async initProducts({ state, commit }) {
    if (state.isInitialized) {
      return;
    }
    if (state.loading) {
      return;
    }
    commit("SET_LOADING", true);
    const products = await _getAllProducts();
    commit("SET_PRODUCTS", products);
    commit("SET_INITIALIZED");
    commit("SET_LOADING", false);
  },
  async addProduct({ commit }, productDict) {
    const product = await _addProduct(productDict);
    commit("ADD_PRODUCT", product);
    return product;
  },
  async updateProduct({ commit }, { id, product }) {
    const updatedProduct = await _updateProduct({ id, product });
    commit("UPDATE_PRODUCT", updatedProduct);
  },
  async deleteProduct({ commit }, { id }) {
    await _deleteProduct({ id });
    commit("DELETE_PRODUCT", id);
  },
};

// mutations
const mutations = {
  SET_PRODUCTS: function (state, products) {
    state.products = products;
  },
  SET_INITIALIZED: function (state) {
    state.isInitialized = true;
  },
  SET_LOADING: function (state, loading) {
    state.loading = loading;
  },
  ADD_PRODUCT: function (state, product) {
    state.products.push(product);
  },
  UPDATE_PRODUCT: function (state, product) {
    const index = state.products.findIndex((x) => x.id == product.id);
    if (index >= 0) {
      state.products.splice(index, 1, product);
    }
  },
  DELETE_PRODUCT: function (state, productId) {
    const products = state.products.filter(
      (product) => product.id != productId
    );
    state.products = products;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
