<template>
  <v-list nav dense expand>
    <side-bar-items
      v-for="(item, index) in items"
      :key="`sidebar-items-${index}`"
      :item="item"
      :index="index"
      :exact="exact"
    ></side-bar-items>
  </v-list>
</template>

<script>
import SideBarItems from "./SideBarItems.vue";
export default {
  components: { SideBarItems },
  name: "SideBar",
  props: {
    items: Array,
    exact: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
