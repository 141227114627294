import Vue from "vue";

const state = () => ({
  event: null,
  eventWidget: null,
});

// getters
const getters = {
  selectedEventWidgetId: (state) => {
    return state.eventWidget?.id;
  },
  eventWidgetConfig: (state) => {
    return state.eventWidget?.config;
  },
  eventWidgetTemplate: (state) => {
    return state.eventWidget?.widgetTemplate;
  },
  eventWidgetCategory: (state, getters) => {
    return getters.eventWidgetTemplate?.category;
  },
  templateConfig: (state, getters) => {
    return getters.eventWidgetTemplate?.config;
  },
  eventTags: (state) => {
    const arr = state.event.widgets.map((widget) =>
      widget.tags ? [...widget.tags] : []
    );
    const set = new Set(arr.flat());
    return [...set];
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  SET_EVENT(state, event) {
    state.event = event;
  },
  SET_EVENT_WIDGET(state, eventWidget) {
    state.eventWidget = eventWidget;
  },
  SET_EVENT_WIDGET_NAME(state, name) {
    state.eventWidget.name = name;
  },
  SET_EVENT_WIDGET_TAGS(state, tags) {
    Vue.set(state.eventWidget, "tags", tags);
  },
  SET_EVENT_WIDGET_FIELD(state, { name, value }) {
    Vue.set(state.eventWidget.config, name, value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
