import { isBefore as _isBefore, parseISO as _parseISO } from "date-fns";
import {
  getLiveStreamEvent as _getLiveStreamEvent,
  incrementLikes as _incrementLikes,
  getMetadata as _getMetadata,
} from "@/service/liveStreamService";
import Vue from "vue";

const state = () => ({
  event: null,
  liveStartAt: null,
  liveEndAt: null,
  views: 0,
  likes: 0,
  isMute: false,
  showDialog: false,
});

// getters
const getters = {
  eventId: (state) => {
    return state.event?.id;
  },
  organization: (state) => {
    return state.event?.organization;
  },
  oid: (state, getters) => {
    return getters.organization?.nameId;
  },
  asset: (state) => {
    return state.event?.asset;
  },
  poster: (state) => {
    return state.event?.poster;
  },
  experts: (state) => {
    if (state.event == null) {
      return [];
    }
    return state.event.expertMappings.map(
      (expertMapping) => expertMapping.expert
    );
  },
  products: (state) => {
    if (state.event == null) {
      return [];
    }
    return state.event.productMappings.map(
      (productMapping) => productMapping.product
    );
  },
  status: (state) => {
    return state.event?.status;
  },
  isActive: (state, getters) => {
    return getters.status == "active";
  },
  isDisconnected: (state, getters) => {
    return getters.status == "disconnected";
  },
  isIdle: (state, getters) => {
    const status = getters.status;
    return status == null || status == "idle";
  },
  isScheduled: (state) => {
    return state.event?.state == "scheduled";
  },
  isTesting: (state) => {
    return state.event?.state == "testing";
  },
  isStarted: (state) => {
    return state.event?.state == "started";
  },
  isFinished: (state) => {
    return state.event?.state == "finished";
  },
  isPastEvent: (state) => {
    if (state.event == null) {
      return false;
    }
    const currentDate = new Date();
    return _isBefore(_parseISO(state.event.startAt), currentDate);
  },
  isAssetReady: (state) => {
    const event = state.event;
    if (event == null) {
      return false;
    }
    //handle legacy cases
    if (event.externalId == null && event.playbackId != null) {
      return true;
    }
    const asset = event.asset;
    if (asset == null) {
      return false;
    }
    return (
      asset.status == "ready" &&
      asset.completed == true &&
      new Date().getTime() / 1000 - asset.completedAt > 60
    );
  },
  livePlaybackId: (state) => {
    return state.event?.playbackId;
  },
  vodPlaybackId: (state) => {
    const event = state.event;
    if (event == null) {
      return null;
    }
    //handle legacy cases
    if (event.externalId == null && event.playbackId != null) {
      return event.playbackId;
    }
    return event.asset?.playbackId;
  },
  isLiveRunning: (state) => {
    return state.liveStartAt != null && state.liveEndAt == null;
  },
  didLiveRun: (state) => {
    return state.liveStartAt != null && state.liveEndAt != null;
  },
};

// actions
const actions = {
  async fetchLiveStreamEventById(
    { commit },
    { organizationNameId, eventUUID }
  ) {
    const event = await _getLiveStreamEvent({
      organizationNameId,
      eventUUID,
    });
    commit("RESET_LIVE_STREAM_EVENT", event);
  },
  async incrementLikes({ state, getters, commit }) {
    if (!state.event) {
      return;
    }
    const { likes } = await _incrementLikes({ eventId: getters.eventId });
    commit("SET_LIKES", likes);
  },
  async fetchMetadata({ state, getters, commit }) {
    if (!state.event) {
      return;
    }
    const { views, likes, status } = await _getMetadata({
      eventId: getters.eventId,
    });
    commit("SET_VIEWS", views);
    commit("SET_LIKES", likes);
    commit("SET_STATUS", status);
  },
  async handleStateEvent({ getters, commit }, { id, state }) {
    if (getters.eventId != id) {
      return;
    }
    commit("SET_STATE", state);
  },
  async handleStatusEvent({ getters, commit }, { id, status, asset }) {
    if (getters.eventId != id) {
      return;
    }
    commit("SET_STATUS", status);
    commit("SET_ASSET", asset);
  },
};

// mutations
const mutations = {
  RESET_LIVE_STREAM_EVENT: function (state, event) {
    state.event = event;
    state.views = 0;
    state.likes = 0;
    state.isMute = false;
  },
  SET_LIVE_START_TIME: function (state, startAt) {
    state.liveStartAt = startAt;
  },
  SET_LIVE_END_TIME: function (state, endAt) {
    state.liveEndAt = endAt;
  },
  SET_VIEWS: function (state, views) {
    state.views = views;
  },
  SET_LIKES: function (state, likes) {
    state.likes = likes;
  },
  SET_STATUS: function (state, status) {
    Vue.set(state.event, "status", status);
  },
  SET_STATE: function (state, eventState) {
    Vue.set(state.event, "state", eventState);
  },
  SET_ASSET: function (state, asset) {
    Vue.set(state.event, "asset", asset);
  },
  SET_MUTE: function (state, isMute) {
    state.isMute = isMute;
  },
  TOGGLE_MUTE: function (state) {
    const isMute = state.isMute;
    state.isMute = !isMute;
  },
  SET_SHOW_DIALOG: function (state, show) {
    state.showDialog = show;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
