import {
  doc as _doc,
  updateDoc as _updateDoc,
  arrayUnion as _arrayUnion,
  arrayRemove as _arrayRemove,
  runTransaction as _runTransaction,
  getDoc as _getDoc,
  onSnapshot as _onSnapshot,
} from "firebase/firestore";
import { primaryDB as db } from "@/firebase";
import { get as _get, post as _post } from "@/service/apiService";

export async function getAllLiveStreamEvents() {
  return await _get("events/get/all");
}

export async function getLiveStreamEvent({ id, uuid }) {
  return await _get("events/get", { id, uuid });
}

export async function addLiveStreamEvent(eventDict) {
  return await _post("events/add", eventDict);
}

export async function updateLiveStreamEvent({ id, event }) {
  return await _post(`events/${id}/update`, event);
}

export async function updateDestinations({ id, destinations }) {
  return await _post(`events/${id}/destinations`, { destinations });
}

export async function cancelLiveStreamEvent({ id }) {
  return await _post(`events/${id}/cancel`);
}

export async function publishLiveStreamEvent({ id }) {
  return await _post(`events/${id}/publish`);
}

export async function unpublishLiveStreamEvent({ id }) {
  return await _post(`events/${id}/unpublish`);
}

export async function startLiveStreamEventTest({ id }) {
  return await _post(`events/${id}/test/start`);
}

export async function endLiveStreamEventTest({ id }) {
  return await _post(`events/${id}/test/end`);
}

export async function startLiveStreamEvent({ id }) {
  return await _post(`events/${id}/start`);
}

export async function endLiveStreamEvent({ id }) {
  return await _post(`events/${id}/end`);
}

export async function addLiveStreamProduct({ eventId, product }) {
  return await _post(`events/${eventId}/products/add`, product);
}

export async function updateLiveStreamProduct({ eventId, productId, product }) {
  return await _post(`events/${eventId}/products/${productId}`, product);
}

export async function deleteLiveStreamProduct({ eventId, productId }) {
  return await _post(`events/${eventId}/products/${productId}/delete`);
}

export async function addLiveStreamWidget({
  eventId,
  widgetTemplateId,
  name,
  config,
}) {
  return await _post(`events/${eventId}/widgets/add`, {
    widgetTemplateId,
    name,
    config,
  });
}

export async function updateLiveStreamWidget({
  eventId,
  widgetId,
  name,
  tags,
  config,
}) {
  return await _post(`events/${eventId}/widgets/${widgetId}`, { name, tags, config });
}

export async function deleteLiveStreamWidget({ eventId, widgetId }) {
  return await _post(`events/${eventId}/widgets/${widgetId}/delete`);
}

export async function updateWidgetsOrder({ eventId, order }) {
  return await _post(`events/${eventId}/widgets/order`, { order });
}

export async function getSubscriberToken({ eventId }) {
  return await _get(`events/${eventId}/studio/subscriber/token`);
}

export async function getEventMetadata({ eventId }) {
  const eventIdStr = `${eventId}`;
  const eventRef = _doc(db, "events", eventIdStr);
  const queryRef = await _getDoc(eventRef);
  return queryRef.exists() ? { id: queryRef.id, ...queryRef.data() } : null;
}

export async function showEventWidget({ eventId, eventWidgetId }) {
  const eventIdStr = `${eventId}`;
  await checkAndCreateEventMetadata({ eventIdStr });
  const eventRef = _doc(db, "events", eventIdStr);
  await _updateDoc(eventRef, {
    visibleWidgets: _arrayUnion(eventWidgetId),
  });
}

export async function hideEventWidget({ eventId, eventWidgetId }) {
  const eventIdStr = `${eventId}`;
  const eventRef = _doc(db, "events", eventIdStr);
  await _updateDoc(eventRef, {
    visibleWidgets: _arrayRemove(eventWidgetId),
  });
}

export async function checkAndCreateEventMetadata({ eventIdStr }) {
  await _runTransaction(db, async (transaction) => {
    const eventRef = _doc(db, "events", eventIdStr);
    const eventDoc = await transaction.get(eventRef);
    if (!eventDoc.exists()) {
      transaction.set(eventRef, {
        visibleWidgets: [],
      });
    }
  });
}

export async function listenEventMetadata({ eventId, listener }) {
  const eventIdStr = `${eventId}`;
  const eventRef = _doc(db, "events", eventIdStr);
  const unsubscribeFunc = _onSnapshot(eventRef, (doc) => {
    if (!doc.metadata.hasPendingWrites) {
      listener({
        metadata: {
          id: doc.id,
          ...doc.data(),
        },
      });
    }
  });
  return unsubscribeFunc;
}
