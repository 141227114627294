<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.28302 0C11.6038 0 12.9937 1.366 13 3.6468V10.3532C13 12.634 11.6164 14 9.28931 14H7.22642C6.96186 13.9713 6.76161 13.7516 6.76161 13.4901C6.76161 13.2285 6.96186 13.0088 7.22642 12.9801H9.28302C11.0566 12.9801 11.9497 12.0962 11.9497 10.3532V3.6468C11.9497 1.90375 11.0566 1.01987 9.28302 1.01987H3.71069C1.93711 1.01987 1.03774 1.90375 1.03774 3.6468V10.3532C1.03774 12.0962 1.93711 12.9801 3.71069 12.9801C3.97525 13.0088 4.17549 13.2285 4.17549 13.4901C4.17549 13.7516 3.97525 13.9713 3.71069 14C1.38994 14 0 12.634 0 10.3532V3.6468C0 1.35982 1.38994 0 3.71069 0H9.28302ZM9.09434 9.45695C9.37917 9.45695 9.61006 9.68388 9.61006 9.9638C9.61006 10.2437 9.37917 10.4706 9.09434 10.4706H3.88679C3.60197 10.4706 3.37107 10.2437 3.37107 9.9638C3.37107 9.68388 3.60197 9.45695 3.88679 9.45695H9.09434ZM9.09434 6.49007C9.29335 6.4685 9.48738 6.5607 9.59377 6.7274C9.70016 6.89409 9.70016 7.10591 9.59377 7.2726C9.48738 7.4393 9.29335 7.5315 9.09434 7.50993H3.88679C3.62224 7.48127 3.42199 7.26158 3.42199 7C3.42199 6.73842 3.62224 6.51873 3.88679 6.49007H9.09434ZM5.87421 3.52936C6.13877 3.55803 6.33901 3.77772 6.33901 4.03929C6.33901 4.30087 6.13877 4.52056 5.87421 4.54923H3.89308C3.62853 4.52056 3.42828 4.30087 3.42828 4.03929C3.42828 3.77772 3.62853 3.55803 3.89308 3.52936H5.87421Z"
      fill="#FF793A"
    />
  </svg>
</template>

<script>
export default {
  name: "OutroScreenWidgetIcon",
};
</script>

<style scoped></style>
