<template>
  <v-main>
    <div class="fill-height">
      <router-view></router-view>
    </div>
  </v-main>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "ContentOnlyLayout",
  created: function () {
    this.setTheme("abby");
  },
  methods: {
    ...mapMutations("core", { setTheme: "SET_THEME" }),
  },
};
</script>

<style scoped></style>
