<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0567 0.5H13.5962C14.6479 0.5 15.5001 1.35939 15.5001 2.41997V4.98089C15.5001 6.04148 14.6479 6.90087 13.5962 6.90087H11.0567C10.005 6.90087 9.15283 6.04148 9.15283 4.98089V2.41997C9.15283 1.35939 10.005 0.5 11.0567 0.5Z"
      fill="#FF793A"
    />
    <path
      d="M4.94337 9.09913C5.99507 9.09913 6.84726 9.95852 6.84726 11.0191V13.58C6.84726 14.6399 5.99507 15.5 4.94337 15.5H2.40389C1.35219 15.5 0.5 14.6399 0.5 13.58V11.0191C0.5 9.95852 1.35219 9.09913 2.40389 9.09913H4.94337ZM13.5961 9.09913C14.6478 9.09913 15.5 9.95852 15.5 11.0191V13.58C15.5 14.6399 14.6478 15.5 13.5961 15.5H11.0566C10.0049 15.5 9.15274 14.6399 9.15274 13.58V11.0191C9.15274 9.95852 10.0049 9.09913 11.0566 9.09913H13.5961ZM4.94337 0.5C5.99507 0.5 6.84726 1.35939 6.84726 2.41997V4.98089C6.84726 6.04148 5.99507 6.90086 4.94337 6.90086H2.40389C1.35219 6.90086 0.5 6.04148 0.5 4.98089V2.41997C0.5 1.35939 1.35219 0.5 2.40389 0.5H4.94337Z"
      fill="#FFD2BD"
    />
  </svg>
</template>

<script>
export default {
  name: "QuizWidgetIcon",
};
</script>

<style scoped></style>
