<template>
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1365 1.34707H10.91C10.8837 1.06433 10.7528 0.801593 10.543 0.610253C10.3332 0.418913 10.0595 0.312739 9.77554 0.3125H7.56531C7.28135 0.312739 7.00769 0.418913 6.79787 0.610253C6.58804 0.801593 6.45716 1.06433 6.43081 1.34707H2.20435C1.62019 1.34862 1.06041 1.58137 0.647352 1.99442C0.234293 2.40748 0.00155154 2.96727 0 3.55142V11.4871C0 12.0717 0.232243 12.6324 0.645638 13.0458C1.05903 13.4592 1.61972 13.6914 2.20435 13.6914H15.1365C15.7211 13.6914 16.2818 13.4592 16.6952 13.0458C17.1086 12.6324 17.3409 12.0717 17.3409 11.4871V3.55142C17.3393 2.96727 17.1066 2.40748 16.6935 1.99442C16.2804 1.58137 15.7207 1.34862 15.1365 1.34707V1.34707ZM7.56531 1.19424H9.77554C9.84569 1.19424 9.91298 1.22211 9.96258 1.27171C10.0122 1.32132 10.0401 1.3886 10.0401 1.45876V1.78206V2.123C10.0401 2.19371 10.0124 2.26161 9.96295 2.31216C9.91351 2.3627 9.84623 2.39187 9.77554 2.3934H7.56531C7.49462 2.39187 7.42735 2.3627 7.3779 2.31216C7.32846 2.26161 7.30078 2.19371 7.30079 2.123V1.78794V1.45876C7.30079 1.3886 7.32866 1.32132 7.37827 1.27171C7.42788 1.22211 7.49516 1.19424 7.56531 1.19424V1.19424ZM16.4591 11.4871C16.4591 11.8378 16.3198 12.1742 16.0717 12.4223C15.8237 12.6703 15.4873 12.8097 15.1365 12.8097H2.20435C1.85357 12.8097 1.51716 12.6703 1.26912 12.4223C1.02108 12.1742 0.881738 11.8378 0.881738 11.4871V3.55142C0.881738 3.20064 1.02108 2.86423 1.26912 2.61619C1.51716 2.36816 1.85357 2.22881 2.20435 2.22881H6.43081C6.46189 2.51073 6.59384 2.77191 6.80233 2.9642C7.01083 3.15649 7.28181 3.26692 7.56531 3.27514H9.77554C10.059 3.26692 10.33 3.15649 10.5385 2.9642C10.747 2.77191 10.879 2.51073 10.91 2.22881H15.1365C15.4873 2.22881 15.8237 2.36816 16.0717 2.61619C16.3198 2.86423 16.4591 3.20064 16.4591 3.55142V11.4871Z"
      fill="#FF793A"
    />
    <path
      d="M14.4486 6.11328H9.74604C9.62912 6.11328 9.51698 6.15973 9.4343 6.24241C9.35162 6.32509 9.30518 6.43722 9.30518 6.55415C9.30518 6.67108 9.35162 6.78321 9.4343 6.86589C9.51698 6.94857 9.62912 6.99502 9.74604 6.99502H14.4486C14.5656 6.99502 14.6777 6.94857 14.7604 6.86589C14.8431 6.78321 14.8895 6.67108 14.8895 6.55415C14.8895 6.43722 14.8431 6.32509 14.7604 6.24241C14.6777 6.15973 14.5656 6.11328 14.4486 6.11328ZM13.0908 8.04723H9.76368C9.64675 8.04723 9.53462 8.09368 9.45194 8.17635C9.36926 8.25903 9.32281 8.37117 9.32281 8.4881C9.32281 8.60502 9.36926 8.71716 9.45194 8.79984C9.53462 8.88252 9.64675 8.92897 9.76368 8.92897H13.0908C13.2077 8.92897 13.3198 8.88252 13.4025 8.79984C13.4852 8.71716 13.5316 8.60502 13.5316 8.4881C13.5316 8.37117 13.4852 8.25903 13.4025 8.17635C13.3198 8.09368 13.2077 8.04723 13.0908 8.04723Z"
      fill="#FFD2BD"
    />
  </svg>
</template>

<script>
export default {
  name: "BannerWidgetIcon",
};
</script>

<style scoped></style>
