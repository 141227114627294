import { getAllExperts as _getAllExperts } from "@/service/expertService";

const state = () => ({
  experts: [],
  isInitialized: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  async initExperts({ state, commit }) {
    if (state.isInitialized) {
      return;
    }
    if (state.loading) {
      return;
    }
    commit("SET_LOADING", true);
    const experts = await _getAllExperts();
    commit("SET_EXPERTS", experts);
    commit("SET_INITIALIZED");
    commit("SET_LOADING", false);
  },
};

// mutations
const mutations = {
  SET_EXPERTS: function (state, experts) {
    state.experts = experts;
  },
  SET_INITIALIZED: function (state) {
    state.isInitialized = true;
  },
  SET_LOADING: function (state, loading) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
