import { get as _get, post as _post } from "@/service/apiService";

export async function getAllLiveStreamEvents() {
  return await _get("livestreams/get/all");
}

export async function getAllLiveStreamEventsForOrg({ oid }) {
  return await _get("livestreams/get/org", {
    oid,
  });
}

export async function getAllLiveStreamProductsForOrg({ oid }) {
  return await _get("livestreams/get/org/products", {
    oid,
  });
}

export async function getLiveStreamEvent({
  organizationId,
  organizationNameId,
  eventUUID,
}) {
  return await _get("livestreams/get", {
    organizationId,
    organizationNameId,
    eventUUID,
  });
}

export async function incrementLikes({ eventId }) {
  return await _post("livestreams/likes", { id: eventId });
}

export async function getMetadata({ eventId }) {
  return await _get("livestreams/metadata", { id: eventId });
}

export async function getLiveStreamEventWithWidgets({
  organizationId,
  organizationNameId,
  eventUUID,
}) {
  return await _get("livestreams/widgets", {
    organizationId,
    organizationNameId,
    eventUUID,
  });
}

export async function getStudioParticipantToken({
  organizationNameId,
  eventUUID,
}) {
  return await _get(
    `livestreams/${organizationNameId}/${eventUUID}/studio/participant/token`
  );
}

export async function getStudioSubscriberToken({
  organizationNameId,
  eventUUID,
}) {
  return await _get(
    `livestreams/${organizationNameId}/${eventUUID}/studio/subscriber/token`
  );
}
