<template>
  <v-menu bottom min-width="300px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :x-large="$vuetify.breakpoint.smAndUp"
        :small="$vuetify.breakpoint.xsOnly"
        v-on="on"
        dark
      >
        <v-avatar color="primary" :size="$vuetify.breakpoint.smAndUp ? 34 : 26">
          <span>{{ initials }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ name }}</v-card-title>
      <v-card-subtitle>{{ email }}</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-list>
          <v-list-item-group color="primary">
            <v-list-item
              :to="{
                name: 'ChangePassword',
              }"
            >
              <v-list-item-icon>
                <v-icon>mdi-key-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Change password</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="signOutAndReload">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sign out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AccountMenu",
  computed: {
    ...mapGetters("user", ["email", "name", "initials"]),
  },
  methods: {
    signOutAndReload: function () {
      this.signOut();
      this.$router.go(0);
    },
    ...mapActions("user", ["signOut"]),
  },
};
</script>

<style scoped></style>
