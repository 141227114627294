<template>
  <v-list-group
    :no-action="item.icon != null"
    :value="true"
    color="primary--text"
    v-if="item.children && item.children.length > 0"
  >
    <v-icon
      slot="prependIcon"
      v-text="item.icon"
      color="primary"
      v-if="item.icon"
    ></v-icon>
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title v-text="item.title"></v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item
      v-for="(childItem, childIndex) in item.children"
      :key="`sidebar-child-${index}-${childIndex}`"
      :to="childItem.target"
      :class="item.icon ? 'ml-4' : 'pl-6'"
      color="primary"
      :exact="exact"
    >
      <v-list-item-content>
        <v-list-item-title v-text="childItem.title"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
  <v-list-item-group color="primary" v-else>
    <v-list-item :to="item.target" :exact="exact">
      <v-list-item-icon v-if="item.icon">
        <v-icon v-text="item.icon" color="primary"></v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="item.title"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
export default {
  name: "SideBarItems",
  props: {
    item: Object,
    index: Number,
    exact: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
