import Vue from "vue";

const state = () => ({
  widgets: {
    logo: {
      imageUrl: "images/brand-logo.png",
    },
    brand: {
      socialMedia: [
        {
          name: "youtube",
          handle: "/ampup",
        },
        {
          name: "facebook",
          handle: "/ampup",
        },
        {
          name: "instagram",
          handle: "/ampup",
        },
        // {
        //   name: "twitter",
        //   handle: "@ampup",
        // },
      ],
      website: "https://www.ampup.live",
      contactNumber: "+91-9999999999",
      email: "support@ampup.live",
    },
    product: {
      name: "Blusher Face Makeup",
      descriptions: [
        "Face Highlight is a buttery, sheer highlighter to gild your skin in candlelight",
        // "Eye Brighten is a sparkling pearly pink shade to light up your eyes",
        // "Eye Enhance is a shimmering berry-pink eyeshadow that suits everyone",
        // "Eye Smoke is a dreamy creamy matte eyeshadow in a deep berry-plum hue",
      ],
      price: "$100.00",
      discountedPrice: "$70.00",
      discount: "30%",
      ratings: 4,
      numRatings: 516,
      images: [
        "images/products/blusher-1.png",
        "images/products/blusher-2.png",
        "images/products/blusher-3.png",
      ],
    },
    // product: {
    //   name: "Cowgirl Boots",
    //   descriptions: [
    //     "High Quality Cowgirl Boots for Women",
    //     "Anti-slip, wear-resistant, shock absorption rubber sole",
    //     "Heel measures approximately 2.9 inches",
    //     "Western boots womens can fit for cosplay, work, office, travel, weddingm prom party, etc.",
    //   ],
    //   price: "$100.00",
    //   discountedPrice: "$70.00",
    //   discount: "30%",
    //   ratings: 4,
    //   numRatings: 516,
    //   images: [
    //     "https://m.media-amazon.com/images/I/61xtMMrcCrL._AC_SY695._SX._UX._SY._UY_.jpg",
    //     "https://m.media-amazon.com/images/I/71+ycGwk9WL._AC_SY695._SX._UX._SY._UY_.jpg",
    //     "https://m.media-amazon.com/images/I/811dcj7ahEL._AC_SY695._SX._UX._SY._UY_.jpg",
    //     "https://m.media-amazon.com/images/I/81d86OsWqsL._AC_SY695._SX._UX._SY._UY_.jpg",
    //     "https://m.media-amazon.com/images/I/81mS+cjimlL._AC_SY695._SX._UX._SY._UY_.jpg",
    //     "https://m.media-amazon.com/images/I/815swL8sceL._AC_SY695._SX._UX._SY._UY_.jpg",
    //   ],
    // },
    // product: {
    //   name: "Designer Chiffon Saree",
    //   price: "₹1,000/-",
    //   discountedPrice: "₹800/-",
    //   images: [
    //     "https://images.pexels.com/photos/7207011/pexels-photo-7207011.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    //     "https://images.pexels.com/photos/7206876/pexels-photo-7206876.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    //     "https://images.pexels.com/photos/7206862/pexels-photo-7206862.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    //     "https://images.pexels.com/photos/7206861/pexels-photo-7206861.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    //   ],
    // },
    banner: {
      name: "Blusher Face Makeup",
      price: "$100.00",
      discountedPrice: "$70.00",
      discount: "30%",
      image: "images/products/blusher-3.png",
    },
    // banner: {
    //   name: "Cowgirl Boots",
    //   price: "$100.00",
    //   discountedPrice: "$70.00",
    //   discount: "30%",
    //   image:
    //     "https://m.media-amazon.com/images/I/81d86OsWqsL._AC_SY695._SX._UX._SY._UY_.jpg",
    // },
    // // banner: {
    // //   name: "Designer Chiffon Saree",
    // //   price: "₹1,000/-",
    // //   discountedPrice: "₹800/-",
    // // },
    limitedDiscountCode: {
      timeInMinutes: 10,
      discount: "30%",
      couponCode: "AMPUP30",
    },
    pinnedMessage: {
      name: "Aaren",
      image: "https://randomuser.me/api/portraits/women/81.jpg",
      message:
        "Foundation should unify tone, but it shouldn’t take away the individuality of the skin",
      channel: "facebook",
    },
    // viewers: [
    //   { name: "facebook", viewers: 1375 },
    //   { name: "youtube", viewers: 2738 },
    // ],
    poll: {
      question: "What should I try next?",
      answers: {
        musk: { text: "Musk", votes: 0, image: "images/polls/musk.png" },
        celestial: {
          text: "Celestial",
          votes: 0,
          image: "images/polls/celestial.png",
        },
        desertSand: {
          text: "Desert Sand",
          votes: 0,
          image: "images/polls/desert-sand.png",
        },
        cashmere: {
          text: "Cashmere",
          votes: 0,
          image: "images/polls/cashmere.png",
        },
      },
      hasEnded: false,
    },
    // quiz: {
    //   question: "What is the shade of this lipstick?",
    //   answers: [
    //     { text: "Red" },
    //     { text: "Blue" },
    //     { text: "Green" },
    //     { text: "Yellow" },
    //   ],
    //   winners: [{ name: "Sanjay" }, { name: "Ravi" }, { name: "Pranav" }],
    //   hasEnded: true,
    // },
    // ticker: {
    //   text: "Limited time offers only. Giveaway entries and discounts are only valid during the live stream. Don't forget to follow and subscribe.",
    // },
    chat: {
      messages: [],
    },
    gamification: [
      {
        name: "Bernetta",
        image: "https://randomuser.me/api/portraits/women/43.jpg",
        channel: "facebook",
        badge: "images/icons/gold-badge.png",
        points: 10345,
      },
      {
        name: "Justine",
        image: "https://randomuser.me/api/portraits/women/44.jpg",
        channel: "youtube",
        badge: "images/icons/gold-badge.png",
        points: 9865,
      },
      {
        name: "Cynthie",
        image: "https://randomuser.me/api/portraits/women/36.jpg",
        channel: "facebook",
        badge: "images/icons/gold-badge.png",
        points: 7432,
      },
      {
        name: "Abagail",
        image: "https://randomuser.me/api/portraits/women/47.jpg",
        channel: "facebook",
        badge: "images/icons/gold-badge.png",
        points: 6737,
      },
      {
        name: "Kacy",
        image: "https://randomuser.me/api/portraits/women/48.jpg",
        channel: "youtube",
        badge: "images/icons/silver-badge.png",
        points: 4876,
      },
    ],
    alert: {
      title: "Congratulations Sally",
      subtitle:
        "For reaching Silver level. Claim your 20% discount coupon code",
      icon: "images/icons/level-up.png",
    },
  },
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  ADD_VOTES: function (state, { answer, votes }) {
    const option = state.widgets.poll.answers[answer];
    option.votes = option.votes + votes;
    state.widgets.poll.answers[answer] = option;
  },
  END_VOTE: function (state) {
    state.widgets.poll.hasEnded = true;
  },
  RESET_POLL: function (state) {
    state.widgets.poll.hasEnded = false;
    for (let answer of Object.keys(state.widgets.poll.answers)) {
      const option = state.widgets.poll.answers[answer];
      option.votes = 0;
      state.widgets.poll.answers[answer] = option;
    }
  },
  ADD_CHAT_MESSAGE: function (state, { message }) {
    state.widgets.chat.messages.push(message);
  },
  CLEAR_CHAT: function (state) {
    state.widgets.chat.messages = [];
  },
  ADD_PINNED_MESSAGE: function (state, { message }) {
    Vue.set(state.widgets.pinnedMessage, "message", message);
  },
  REMOVE_PINNED_MESSAGE: function (state) {
    Vue.set(state.widgets.pinnedMessage, "message", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
