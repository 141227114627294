import Vue from "vue";
import Vuex from "vuex";
import core from "./modules/core";
import user from "./modules/user";
import chat from "./modules/chat";
import liveStream from "./modules/liveStream";
import userEvent from "./modules/userEvent";
import oauth from "./modules/oauth";
import widget from "./modules/widget";
import product from "./modules/product";
import expert from "./modules/expert";
import socialNetwork from "./modules/socialNetwork";
import widgetManagement from "./modules/widgetManagement";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    core,
    user,
    chat,
    liveStream,
    userEvent,
    oauth,
    widget,
    product,
    expert,
    socialNetwork,
    widgetManagement,
  },
});
