import store from "@/store";

export function getImageUrl(objectName) {
  if (objectName == null) {
    return null;
  }
  const isHttpUrl = objectName.startsWith("http");
  return isHttpUrl ? objectName : getS3ImageUrl(objectName);
}

export function getS3ImageUrl(objectName) {
  if (objectName == null) {
    return null;
  }
  if (store.state.core.useImageProxy) {
    const baseURL = process.env.VUE_APP_API_SERVER_BASE_URL + "/api/v1";
    return `${baseURL}/assets/images/${objectName}`;
  } else {
    return `https://s3.ap-south-1.amazonaws.com/${process.env.VUE_APP_BUCKET_NAME}/${objectName}`;
  }
  // return objectName
  //   ? `https://s3.ap-south-1.amazonaws.com/${process.env.VUE_APP_BUCKET_NAME}/${objectName}`
  //   : null;
  // return objectName
  //   ? `https://${process.env.VUE_APP_BUCKET_NAME}.s3.ap-south-1.amazonaws.com/${objectName}`
  //   : null;
}

export function getSpacesImageUrl(objectName) {
  return objectName
    ? `https://${process.env.VUE_APP_BUCKET_NAME}.sgp1.cdn.digitaloceanspaces.com/${objectName}`
    : null;
}
