<template>
  <div class="d-flex justify-center align-center fill-height">
    <div>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutLoader",
};
</script>

<style scoped></style>
