<template>
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.02898 13.1329L6.33333 13.3664L6.63769 13.1329L8.45304 11.7401H14.3333C15.5289 11.7401 16.5 10.7729 16.5 9.57769V2.92804C16.5 1.73265 15.5288 0.765625 14.3333 0.765625H2.66667C1.47117 0.765625 0.5 1.73265 0.5 2.92804V9.57769C0.5 10.7729 1.47115 11.7401 2.66667 11.7401H4.21362L6.02898 13.1329Z"
      fill="white"
      stroke="#FFC9B0"
    />
    <rect
      x="3.64697"
      y="3.91016"
      width="6.17647"
      height="1.76471"
      fill="#FF8032"
    />
    <rect
      x="3.64697"
      y="6.55469"
      width="9.70588"
      height="1.76471"
      fill="#FF8032"
    />
  </svg>
</template>

<script>
export default {
  name: "IntroBannerWidgetIcon",
};
</script>

<style scoped></style>
