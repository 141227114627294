import { get as _get, post as _post } from "@/service/apiService";

export async function getAllOauths() {
  return await _get("oauths/get/all");
}

export async function updateFBOauth({ socialNetworkId, data }) {
  return await _post("oauths/fb/update", { socialNetworkId, data });
}

export async function getFBDeletionStatus({ id }) {
  return await _get(`oauths/fb/deletion_status/${id}`);
}

export async function authorizeGoogleOauth({
  socialNetworkId,
  successUrl,
  errorUrl,
}) {
  const { url } = await _post("oauths/google/authorize", {
    socialNetworkId,
    successUrl,
    errorUrl,
  });
  window.location.href = url;
}

export async function revokeGooglePermissions({ socialNetworkId }) {
  return await _get(`oauths/google/revoke_permissions`, { socialNetworkId });
}

export async function getGoogleMissingPermissions({ socialNetworkId }) {
  return await _get(`oauths/google/missing_permissions`, { socialNetworkId });
}

export async function revokeFBPermissions({ socialNetworkId }) {
  return await _get(`oauths/fb/revoke_permissions`, { socialNetworkId });
}
