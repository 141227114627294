<template>
  <div class="fill-height">
    <partner-app-bar></partner-app-bar>
    <v-main>
      <div class="fill-height">
        <v-container fluid fill-height class="pa-0">
          <v-row class="fill-height" no-gutters>
            <v-col>
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
    <partner-footer class="mt-4"></partner-footer>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import PartnerAppBar from "../partner/PartnerAppBar.vue";
import PartnerFooter from "../partner/PartnerFooter.vue";
export default {
  components: { PartnerAppBar, PartnerFooter },
  name: "PartnerLayout",
  created: function () {
    this.setTheme("partner");
  },
  methods: {
    ...mapMutations("core", { setTheme: "SET_THEME" }),
  },
};
</script>

<style scoped></style>
