import {
  parseISO as _parseISO,
  differenceInDays as _differenceInDays,
  differenceInHours as _differenceInHours,
  differenceInMinutes as _differenceInMinutes,
  addDays as _addDays,
  addHours as _addHours,
  isDate as _isDate,
  isAfter as _isAfter,
} from "date-fns";

export function calculateTimeDifference(first, second, short = false) {
  if (first == null || second == null) {
    return null;
  }
  const result = [];
  const firstDate = _isDate(first) ? first : _parseISO(first);
  const secondDate = _isDate(second) ? second : _parseISO(second);
  let smallerDate = null;
  let largerDate = null;
  if (_isAfter(firstDate, secondDate)) {
    largerDate = firstDate;
    smallerDate = secondDate;
  } else {
    largerDate = secondDate;
    smallerDate = firstDate;
  }
  let difference = smallerDate;
  const days = _differenceInDays(largerDate, difference);
  if (days > 0) {
    result.push(short ? `${days}d` : `${days} days`);
    difference = _addDays(difference, days);
  }
  const hours = _differenceInHours(largerDate, difference);
  if (hours > 0) {
    result.push(short ? `${hours}h` : `${hours} hours`);
    difference = _addHours(difference, hours);
  }
  const minutes = _differenceInMinutes(largerDate, difference);
  if (minutes > 0) {
    result.push(short ? `${minutes}m` : `${minutes} minutes`);
  }
  return result.length == 0 ? "now" : result.join(", ");
}
