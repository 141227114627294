<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1275_82895)">
      <path
        d="M14.25 2.25H11.115C10.8 1.38 9.975 0.75 9 0.75C8.025 0.75 7.2 1.38 6.885 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM9 2.0625C9.165 2.0625 9.3075 2.1375 9.4125 2.25C9.5025 2.3475 9.5625 2.4825 9.5625 2.625C9.5625 2.9325 9.3075 3.1875 9 3.1875C8.6925 3.1875 8.4375 2.9325 8.4375 2.625C8.4375 2.4825 8.4975 2.3475 8.5875 2.25C8.6925 2.1375 8.835 2.0625 9 2.0625ZM14.25 14.25H3.75V3.75H14.25V14.25ZM9 4.5C7.7625 4.5 6.75 5.5125 6.75 6.75C6.75 7.9875 7.7625 9 9 9C10.2375 9 11.25 7.9875 11.25 6.75C11.25 5.5125 10.2375 4.5 9 4.5ZM9 7.5C8.5875 7.5 8.25 7.1625 8.25 6.75C8.25 6.3375 8.5875 6 9 6C9.4125 6 9.75 6.3375 9.75 6.75C9.75 7.1625 9.4125 7.5 9 7.5ZM4.5 12.3525V13.5H13.5V12.3525C13.5 10.4775 10.5225 9.6675 9 9.6675C7.4775 9.6675 4.5 10.47 4.5 12.3525ZM6.2325 12C6.75 11.58 8.0175 11.16 9 11.16C9.9825 11.16 11.2575 11.58 11.7675 12H6.2325Z"
        fill="#FF793A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1275_82895">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ProductPosterIcon",
};
</script>

<style scoped></style>
