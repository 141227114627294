import { get as _get } from "@/service/apiService";

export async function getAllExperts() {
  return await _get("experts/get/all");
}

export async function getActiveExperts() {
  return await _get("experts/get/active");
}

export async function getExpertById({ id }) {
  return await _get("experts/get", { id });
}

export async function getExpertByNameId({ nameId }) {
  return await _get("experts/get", { nameId });
}

export async function getExpertLiveStreams({ id }) {
  return await _get("livestreams/expert/events", { id });
}
