<template>
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.805 1.27833C14.5899 1.12616 14.3412 1.02807 14.0801 0.99239C13.819 0.956705 13.5531 0.98447 13.305 1.07333C9.87358 2.29343 6.12645 2.29343 2.69501 1.07333C2.44688 0.984094 2.18089 0.956003 1.9196 0.991441C1.6583 1.02688 1.4094 1.1248 1.194 1.2769C0.978601 1.42901 0.803055 1.63081 0.682246 1.8652C0.561436 2.09958 0.498925 2.35965 0.500014 2.62333V9.37333C0.498925 9.63702 0.561436 9.89708 0.682246 10.1315C0.803055 10.3659 0.978601 10.5677 1.194 10.7198C1.4094 10.8719 1.6583 10.9698 1.9196 11.0052C2.18089 11.0407 2.44688 11.0126 2.69501 10.9233C6.12645 9.70323 9.87358 9.70323 13.305 10.9233C13.4814 10.9868 13.6675 11.0189 13.855 11.0183C14.0712 11.019 14.2854 10.9769 14.4853 10.8945C14.6852 10.812 14.8668 10.6909 15.0197 10.538C15.1726 10.3851 15.2937 10.2035 15.3761 10.0036C15.4586 9.80375 15.5007 9.58954 15.5 9.37333V2.62333C15.5003 2.35987 15.4374 2.10019 15.3164 1.86613C15.1955 1.63207 15.0201 1.43048 14.805 1.27833ZM14.5 9.37333C14.5028 9.47794 14.48 9.58164 14.4338 9.6755C14.3875 9.76936 14.3191 9.85055 14.2344 9.91207C14.1498 9.97359 14.0514 10.0136 13.9479 10.0286C13.8443 10.0436 13.7387 10.0332 13.64 9.99833C9.99238 8.69832 6.00765 8.69832 2.36001 9.99833C2.26137 10.0332 2.15571 10.0436 2.05215 10.0286C1.9486 10.0136 1.85025 9.97359 1.7656 9.91207C1.68095 9.85055 1.61254 9.76936 1.56627 9.6755C1.52 9.58164 1.49726 9.47794 1.50001 9.37333V2.62333C1.49726 2.51873 1.52 2.41502 1.56627 2.32116C1.61254 2.22731 1.68095 2.14612 1.7656 2.0846C1.85025 2.02308 1.9486 1.98308 2.05215 1.96805C2.15571 1.95302 2.26137 1.96342 2.36001 1.99833C6.00765 3.29834 9.99238 3.29834 13.64 1.99833C13.7387 1.96342 13.8443 1.95302 13.9479 1.96805C14.0514 1.98308 14.1498 2.02308 14.2344 2.0846C14.3191 2.14612 14.3875 2.22731 14.4338 2.32116C14.48 2.41502 14.5028 2.51873 14.5 2.62333V9.37333Z"
      fill="#FF793A"
    />
    <rect x="3.5" y="4.5" width="9" height="3" fill="#FFD2BD" />
  </svg>
</template>

<script>
export default {
  name: "IntroScreenWidgetIcon",
};
</script>

<style scoped></style>
