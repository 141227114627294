<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="image-icon"
  >
    <g opacity="0.15" clip-path="url(#clip0_1231_80649)">
      <path
        d="M13.5 15H3V4.5H9.75V3H3C2.175 3 1.5 3.675 1.5 4.5V15C1.5 15.825 2.175 16.5 3 16.5H13.5C14.325 16.5 15 15.825 15 15V8.25H13.5V15ZM7.6575 12.6225L6.1875 10.8525L4.125 13.5H12.375L9.72 9.9675L7.6575 12.6225ZM15 3V0.75H13.5V3H11.25C11.2575 3.0075 11.25 4.5 11.25 4.5H13.5V6.7425C13.5075 6.75 15 6.7425 15 6.7425V4.5H17.25V3H15Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1231_80649">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ImageIcon",
  props: {
    fill: String,
  },
};
</script>

<style scoped>
.image-icon {
  fill: currentColor;
}
</style>
