// const routes = [
//   {
//     path: "/user",
//     component: () =>
//       import(/* webpackChunkName: "user" */ "../views/UserView.vue"),
//     children: [
//       {
//         path: "login",
//         name: "Login",
//         props: (route) => ({ url: route.query.url }),
//         component: () =>
//           import(/* webpackChunkName: "login" */ "../views/Login.vue"),
//         meta: {
//           requiresNoLogin: true,
//         },
//       },
//     ],
//   },
//   {
//     path: "/portal",
//     component: () =>
//       import(/* webpackChunkName: "portal" */ "../views/PortalView.vue"),
//     meta: {
//       requiresVerification: true,
//     },
//     children: [
//       {
//         path: "",
//         redirect: "events",
//         name: "PortalHome",
//         component: () =>
//           import(
//             /* webpackChunkName: "portal-home" */ "../views/PortalHome.vue"
//           ),
//       },
//       {
//         path: "events",
//         name: "Events",
//         component: () =>
//           import(
//             /* webpackChunkName: "events" */ "../views/UserEventsView.vue"
//           ),
//       },
//       {
//         path: "integrations",
//         name: "Integrations",
//         component: () =>
//           import(
//             /* webpackChunkName: "integrations" */ "../views/Integrations.vue"
//           ),
//       },
//       {
//         path: "event/:event",
//         component: () =>
//           import(
//             /* webpackChunkName: "event" */ "../views/UserEventWrapper.vue"
//           ),
//         props: (route) => ({ uuid: route.params.event }),
//         children: [
//           {
//             path: "",
//             name: "Event",
//             component: () =>
//               import(
//                 /* webpackChunkName: "event-view" */ "../views/EventView.vue"
//               ),
//             props: (route) => ({ dest: route.query.dest }),
//           },
//           {
//             path: "edit",
//             name: "EditEvent",
//             component: () =>
//               import(
//                 /* webpackChunkName: "event-edit" */ "../views/EditEvent.vue"
//               ),
//           },
//           {
//             path: "product/add",
//             name: "AddProduct",
//             component: () =>
//               import(
//                 /* webpackChunkName: "add-product" */ "../views/AddProduct.vue"
//               ),
//           },
//           {
//             path: "product/:product",
//             name: "EditProduct",
//             component: () =>
//               import(
//                 /* webpackChunkName: "edit-product" */ "../views/EditProduct.vue"
//               ),
//             props: (route) => ({ uuid: route.params.product }),
//           },
//         ],
//       },
//     ],
//   },
// ];

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      layout: "web",
    },
  },
  {
    path: "/partner",
    name: "PartnerHome",
    component: () =>
      import(/* webpackChunkName: "partner-home" */ "../views/PartnerHome.vue"),
    meta: {
      layout: "partner",
    },
  },
  {
    path: "/brands/:brand",
    name: "Brand",
    component: () =>
      import(/* webpackChunkName: "brand" */ "../views/Brand.vue"),
    props: (route) => ({ nameId: route.params.brand }),
    meta: {
      layout: "web",
    },
  },
  {
    path: "/experts/:expert",
    name: "Expert",
    component: () =>
      import(/* webpackChunkName: "expert" */ "../views/Expert.vue"),
    props: (route) => ({ nameId: route.params.expert }),
    meta: {
      layout: "web",
    },
  },
  {
    path: "/upcoming",
    name: "Upcoming",
    component: () =>
      import(/* webpackChunkName: "upcoming" */ "../views/Upcoming.vue"),
    meta: {
      layout: "web",
    },
  },
  {
    path: "/replays",
    name: "Replays",
    component: () =>
      import(/* webpackChunkName: "replays" */ "../views/Replays.vue"),
    meta: {
      layout: "web",
    },
  },
  {
    path: "/:organization/player",
    name: "LiveStreamPlayer",
    props: (route) => ({
      organizationNameId: route.params.organization,
      eventUUID: route.query.event,
      source: route.query.source,
    }),
    component: () =>
      import(
        /* webpackChunkName: "live-stream-player" */ "../views/LiveStreamPlayer.vue"
      ),
    meta: {
      layout: "content-only",
    },
  },
  {
    path: "/:organization/:event/widgets",
    name: "EventWidgetsOverlay",
    props: (route) => ({
      organizationNameId: route.params.organization,
      eventUUID: route.params.event,
    }),
    component: () =>
      import(
        /* webpackChunkName: "event-widgets-overlay" */ "../views/EventWidgetsOverlay.vue"
      ),
    meta: {
      layout: "content-only",
    },
  },
  {
    path: "/:organization/:event/stream/input",
    name: "LiveStreamInput",
    component: () =>
      import(
        /* webpackChunkName: "stream-input" */ "../views/LiveStreamInput.vue"
      ),
    props: (route) => ({
      organizationNameId: route.params.organization,
      eventUUID: route.params.event,
    }),
    meta: {
      layout: "content-only",
    },
  },
  {
    path: "/:organization/:event/studio",
    name: "LiveStreamStudio",
    component: () =>
      import(/* webpackChunkName: "studio" */ "../views/LiveStreamStudio.vue"),
    props: (route) => ({
      organizationNameId: route.params.organization,
      eventUUID: route.params.event,
    }),
    meta: {
      layout: "app",
    },
  },
  {
    path: "/:brand/haul",
    name: "ProductHaul",
    props: (route) => ({
      brandNameId: route.params.brand,
      eventId: route.query.event,
    }),
    component: () =>
      import(/* webpackChunkName: "product-haul" */ "../views/ProductHaul.vue"),
    meta: {
      layout: "web",
    },
  },
  {
    path: "/user",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/UserView.vue"),
    meta: {
      layout: "app",
    },
    children: [
      {
        path: "login",
        name: "Login",
        props: (route) => ({ url: route.query.url }),
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Login.vue"),
        meta: {
          requiresNoLogin: true,
        },
      },
      {
        path: "change-password",
        name: "ChangePassword",
        component: () =>
          import(
            /* webpackChunkName: "change-password" */ "../views/ChangePassword.vue"
          ),
        meta: {
          requiresLogin: true,
        },
      },
      {
        path: "forgotPassword",
        name: "ForgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "forgot-password" */ "../views/ForgotPassword.vue"
          ),
        meta: {
          requiresNoLogin: true,
        },
      },
      {
        path: "auth/action",
        name: "ResetPassword",
        props: (route) => ({
          mode: route.query.mode,
          oobCode: route.query.oobCode,
          apiKey: route.query.apiKey,
        }),
        component: () =>
          import(
            /* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"
          ),
        meta: {
          requiresNoLogin: true,
        },
      },
    ],
  },
  {
    path: "/portal",
    component: () =>
      import(/* webpackChunkName: "portal" */ "../views/PortalView.vue"),
    meta: {
      layout: "portal",
      requiresVerification: true,
    },
    children: [
      {
        path: "",
        redirect: "events",
        name: "PortalHome",
        component: () =>
          import(
            /* webpackChunkName: "portal-home" */ "../views/PortalHome.vue"
          ),
      },
      {
        path: "events",
        name: "Events",
        component: () =>
          import(
            /* webpackChunkName: "events" */ "../views/UserEventsView.vue"
          ),
      },
      {
        path: "destinations",
        name: "Destinations",
        component: () =>
          import(
            /* webpackChunkName: "destinations" */ "../views/Integrations.vue"
          ),
      },
      {
        path: "destinations/missing-permissions-page",
        name: "MissingPermissionsErrorPage",
        component: () =>
          import(
            /* webpackChunkName: "missing-permissions-page" */ "../views/MissingPermissionsErrorPage.vue"
          ),
      },
      {
        path: "products",
        name: "Products",
        component: () =>
          import(
            /* webpackChunkName: "products" */ "../views/UserProductsView.vue"
          ),
      },
      {
        path: "product/add",
        name: "AddProduct",
        component: () =>
          import(
            /* webpackChunkName: "add-product" */ "../views/AddProduct.vue"
          ),
      },
      {
        path: "product/:product",
        name: "EditProduct",
        component: () =>
          import(
            /* webpackChunkName: "edit-product" */ "../views/EditProduct.vue"
          ),
        props: (route) => ({ id: route.params.product }),
      },
      {
        path: "event/:event",
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/UserEventWrapper.vue"
          ),
        props: (route) => ({ uuid: route.params.event }),
        children: [
          {
            path: "",
            name: "Event",
            component: () =>
              import(
                /* webpackChunkName: "event-view" */ "../views/EventView.vue"
              ),
            props: (route) => ({ dest: route.query.dest }),
          },
          {
            path: "edit",
            name: "EditEvent",
            component: () =>
              import(
                /* webpackChunkName: "event-edit" */ "../views/EditEvent.vue"
              ),
          },
          {
            path: "widgets",
            name: "WidgetsManagement",
            component: () =>
              import(
                /* webpackChunkName: "widgets-management" */ "../views/WidgetsManagement.vue"
              ),
            meta: {
              layout: "no-sidebar-portal",
              breadCrumbs: false,
            },
          },
          {
            path: "control-panel",
            name: "ControlPanel",
            component: () =>
              import(
                /* webpackChunkName: "control-panel" */ "../views/ControlPanel.vue"
              ),
            meta: {
              layout: "no-sidebar-portal",
              breadCrumbs: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/facebook/delete/status/:id",
    name: "FacebookDeleteStatus",
    component: () =>
      import(
        /* webpackChunkName: "facebook-delete-status" */ "../views/FacebookDeleteStatus.vue"
      ),
    props: (route) => ({ id: route.params.id }),
  },
  {
    path: "/page-not-found",
    name: "PageNotFound",
    component: () =>
      import(
        /* webpackChunkName: "page-not-found" */ "../views/PageNotFound.vue"
      ),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/Forbidden.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/PrivacyPolicy.vue"),
    meta: {
      layout: "web",
    },
  },
  {
    path: "/tnc",
    name: "TermsConditions",
    component: () =>
      import(/* webpackChunkName: "tnc" */ "../views/TermsConditions.vue"),
    meta: {
      layout: "web",
    },
  },
  {
    path: "*",
    redirect: { name: "PageNotFound" },
  },
];

export { routes };
