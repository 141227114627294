import { get as _get } from "@/service/apiService";

export async function getAllOrganizations() {
  return await _get("organizations/get/all", {
    enabled: false,
  });
}

export async function getEnabledOrganizations() {
  return await _get("organizations/get/all", {
    enabled: true,
  });
}

export async function getOrganizationById({ id }) {
  return await _get("organizations/get", { id });
}

export async function getOrganizationByNameId({ nameId }) {
  return await _get("organizations/get", { nameId });
}
