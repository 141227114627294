import { render, staticRenderFns } from "./PollsWidgetIcon.vue?vue&type=template&id=999c99dc&scoped=true&"
import script from "./PollsWidgetIcon.vue?vue&type=script&lang=js&"
export * from "./PollsWidgetIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "999c99dc",
  null
  
)

export default component.exports