import {
  doc,
  collection,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  query,
  getDocs,
  orderBy,
  limit as _limit,
  onSnapshot,
  runTransaction,
  getDoc as _getDoc,
  where as _where,
} from "firebase/firestore";
import { chatDB as db } from "@/firebase";

export async function getUser(userId) {
  const userRef = doc(db, "users", userId);
  const queryRef = await _getDoc(userRef);
  return queryRef.exists() ? { id: queryRef.id, ...queryRef.data() } : null;
}

export async function createUser({ userId }) {
  const userRef = doc(db, "users", userId);
  await setDoc(userRef, {}, { merge: true });
}

export async function updateUserName({ userId, userName }) {
  const userRef = doc(db, "users", userId);
  await setDoc(userRef, { name: userName }, { merge: true });
}

export async function addRole({ userId, role }) {
  const userRef = doc(db, "users", userId);
  await setDoc(userRef, { role }, { merge: true });
}

export async function checkAndCreateChannel({ channelId }) {
  await runTransaction(db, async (transaction) => {
    const channelRef = doc(db, "channels", channelId);
    const channelDoc = await transaction.get(channelRef);
    if (!channelDoc.exists()) {
      transaction.set(channelRef, {
        users: [],
        muted: [],
        updateTime: serverTimestamp(),
      });
    }
  });
}

export async function subscribe({ channelId, userId }) {
  await checkAndCreateChannel({ channelId });
  const channelRef = doc(db, "channels", channelId);
  await updateDoc(channelRef, {
    users: arrayUnion(userId),
  });
  const queryRef = await _getDoc(channelRef);
  return queryRef.exists() ? { id: queryRef.id, ...queryRef.data() } : null;
}

export async function unsubscribe({ channelId, userId }) {
  // todo the user can subscribe from multiple tabs but is removed as soon as it navigates on a single tab
  // todo so for removing user from db we will need to keep a count which increments for each subscribe
  // todo and decrements for each unsubscribe
  const enableUnsubscribe = false;
  if (enableUnsubscribe) {
    const channelRef = doc(db, "channels", channelId);
    await updateDoc(channelRef, {
      users: arrayRemove(userId),
    });
  }
}

export async function sendMessage({
  senderId,
  senderName,
  channelId,
  message,
}) {
  const messagesRef = doc(collection(db, "channels", channelId, "messages"));
  await setDoc(messagesRef, {
    sender: {
      id: senderId,
      name: senderName,
    },
    timestamp: serverTimestamp(),
    updateTime: serverTimestamp(),
    message,
  });
}

export async function getDummyMessage({ senderId, senderName, message }) {
  const curTime = Date.now();
  const id = `dummy-${senderId}-${Math.random().toString(36)}-${curTime}`;
  return {
    id,
    sender: {
      id: senderId,
      name: senderName,
    },
    timestamp: {
      seconds: curTime / 1000,
      nanoseconds: 0,
    },
    updateTime: {
      seconds: curTime / 1000,
      nanoseconds: 0,
    },
    message,
  };
}

export async function removeMessage({ channelId, messageId }) {
  const messageRef = doc(db, "channels", channelId, "messages", messageId);
  await updateDoc(messageRef, {
    removedAt: serverTimestamp(),
    updateTime: serverTimestamp(),
  });
}

export async function muteUser({ channelId, userId }) {
  const channelRef = doc(db, "channels", channelId);
  await updateDoc(channelRef, {
    muted: arrayUnion(userId),
    updateTime: serverTimestamp(),
  });
}

export async function unmuteUser({ channelId, userId }) {
  const channelRef = doc(db, "channels", channelId);
  await updateDoc(channelRef, {
    muted: arrayRemove(userId),
    updateTime: serverTimestamp(),
  });
}

export async function listenChannels({ userId, listener }) {
  const channelRef = collection(db, "channels");
  const queryRef = query(
    channelRef,
    _where("users", "array-contains", userId),
    orderBy("updateTime", "desc"),
    _limit(1)
  );
  const unsubscribeFunc = onSnapshot(queryRef, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if (!doc.metadata.hasPendingWrites) {
        listener({
          channel: {
            id: doc.id,
            ...doc.data(),
          },
        });
      }
    });
  });
  return unsubscribeFunc;
}

export async function listenMessages({ channelId, autoload, listener }) {
  const messages = [];
  if (autoload == null || autoload > 0) {
    const oldMessages = await getMessages({ channelId, limit: autoload });
    messages.push(...oldMessages);
  }
  const messagesRef = collection(db, "channels", channelId, "messages");
  const queryRef = query(messagesRef, orderBy("updateTime", "desc"), _limit(1));
  const unsubscribeFunc = onSnapshot(queryRef, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if (!doc.metadata.hasPendingWrites) {
        listener({
          message: {
            id: doc.id,
            ...doc.data(),
          },
        });
      }
    });
  });
  return { messages, unsubscribeFunc };
}

export async function getMessages({ channelId, limit }) {
  const messagesRef = collection(db, "channels", channelId, "messages");
  let queryRef;
  if (limit != null) {
    queryRef = query(messagesRef, orderBy("timestamp", "desc"), _limit(limit));
  } else {
    queryRef = query(messagesRef, orderBy("timestamp", "desc"));
  }
  const querySnapshot = await getDocs(queryRef);
  const messages = [];
  querySnapshot.forEach((doc) => {
    messages.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return messages;
}
