<template>
  <v-app-bar :height="height" elevation="1" clipped-left clipped-right app>
    <app-bar-logo :homeRoute="{ name: 'PortalHome' }"></app-bar-logo>
    <v-autocomplete
      v-model="selectedEvent"
      :loading="loading"
      :items="events"
      :filter="isMatchedEvent"
      :search-input.sync="search"
      placeholder="Search events by title, expert"
      append-icon=""
      prepend-inner-icon="mdi-magnify"
      flat
      solo
      dense
      hide-no-data
      hide-details
      hide-selected
      class="mx-4"
      item-text="title"
      return-object
    >
    </v-autocomplete>
    <v-spacer></v-spacer>
    <account-menu></account-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import AppBarLogo from "../logo/AppBarLogo.vue";
import AccountMenu from "./AccountMenu.vue";
export default {
  components: { AccountMenu, AppBarLogo },
  name: "PortalAppBar",
  watch: {
    selectedEvent: async function (newVal) {
      if (newVal) {
        try {
          await this.$router.push({
            name: "Event",
            params: { event: newVal.uuid },
          });
        } catch (err) {
          // needed to handle same path navigation(NavigationDuplicated error)
          // https://github.com/vuejs/vue-router/issues/2872
          // do nothing
        } finally {
          this.selectedEvent = null;
        }
      }
    },
  },
  data: function () {
    return {
      height: 70,
      selectedEvent: null,
      loading: false,
      search: null,
    };
  },
  computed: {
    filteredEvents: function () {
      const events = this.events.filter((event) =>
        this.isMatchedEvent(event, this.search)
      );
      return events.slice(0, 5);
    },
    ...mapState("userEvent", ["events"]),
  },
  methods: {
    isMatchedEvent: function (event, search) {
      if (!search) {
        return true;
      }
      const searchText = search.toLowerCase();
      const title = event.title.toLowerCase();
      if (title.indexOf(searchText) > -1) {
        return true;
      }
      const expertNames = event.expertMappings.map(
        (expertMapping) => expertMapping.expert.name
      );
      for (let expertName of expertNames) {
        if (expertName.toLowerCase().indexOf(searchText) > -1) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped></style>
