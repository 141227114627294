import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { routes } from "./route";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // hide navigation drawer before each route
  store.commit("core/SET_SHOW_NAVIGATION_DRAWER", false, { root: true });
  //set system message to null before each route
  store.commit("core/SET_SYSTEM_MESSAGE", null, { root: true });

  const isDirty = store.state.core.isDirty;
  if (isDirty) {
    const answer = window.confirm("Changes that you made may not be saved.");
    if (!answer) {
      next(false);
      return;
    }
  }

  setLayout(to);

  let path = null;
  const requiresNoLogin = noLoginRequired(to);
  const requiresLogin = loginRequired(to);
  const requiresVerification = verificationRequired(to);
  const isAuthenticated = store.getters["user/isAuthenticated"];
  const emailVerified = store.getters["user/emailVerified"];
  if (requiresNoLogin === true && isAuthenticated === true) {
    path = { name: "PortalHome" };
  } else if (requiresLogin === true && isAuthenticated === false) {
    path = { name: "Login", query: { url: getNextUrl(to) } };
  } else if (requiresVerification === true && emailVerified === false) {
    path = { name: "SendVerificationEmail", query: { url: getNextUrl(to) } };
  }
  store.commit("core/SET_DIRTY_FLAG", false, { root: true });
  if (path != null) {
    next(path);
  } else {
    next();
  }
});

function setLayout(route) {
  let layout = route.meta.layout;
  if (!layout) {
    const matchedRoute = route.matched.find(
      (route) => route.meta.layout != null
    );
    layout = matchedRoute?.meta.layout;
  }
  layout = layout || "app";
  store.commit("core/SET_LAYOUT", layout, { root: true });
}

function getNextUrl(route) {
  return route.query.url || route.fullPath;
}

function noLoginRequired(route) {
  return route.matched.some((x) => x.meta.requiresNoLogin);
}

function loginRequired(route) {
  return route.matched.some(
    (x) => x.meta.requiresLogin || x.meta.requiresVerification
  );
}

function verificationRequired(route) {
  return route.matched.some((x) => x.meta.requiresVerification);
}

export default router;
