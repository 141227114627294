import { auth } from "@/firebase";
import {
  addUserProfile as _addUserProfile,
  canImpersonateUsers as _canImpersonateUsers,
  getUserProfile as _getUserProfile,
  impersonateOrganization as _impersonateOrganization,
  updateUserProfile as _updateUserProfile
} from "@/service/userService";
import {
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  reload,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  updateProfile
} from "firebase/auth";

const state = () => ({
  fbUser: null,
  fbUserAttributes: null,
  profile: null,
  canImpersonate: false,
});

// getters
const getters = {
  fbId: (state) => {
    return state.fbUserAttributes?.userId;
  },
  userId: (state) => {
    return state.profile?.id;
  },
  isAuthenticated: (state) => {
    return state.fbUserAttributes != null;
  },
  email: (state) => {
    return state.fbUserAttributes?.email;
  },
  name: (state) => {
    return state.fbUserAttributes?.name;
  },
  emailVerified: (state) => {
    return state.fbUserAttributes?.emailVerified;
  },
  profileDetails: (state) => {
    return state.profile;
  },
  originalOrganization: (state, getters) => {
    return getters.profileDetails?.organization;
  },
  organization: (state, getters) => {
    return getters.profileDetails?.currentOrganization;
  },
  organizationName: (state, getters) => {
    return getters.organization?.name;
  },
  oid: (state, getters) => {
    return getters.organization?.nameId;
  },
  isSetupComplete: (state, getters) => {
    return getters.setup?.complete;
  },
  dataJson: (state, getters) => {
    return getters.profileDetails?.dataJson;
  },
  accounts: (state, getters) => {
    return getters.dataJson?.accounts || [];
  },
  initials: (state, getters) => {
    if (getters.name != null) {
      const names = getters.name.split(" ");
      let initials = names[0].substring(0, 1).toUpperCase();
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    }
    return null;
  },
  isDevUser: () => {
    return process.env.VUE_APP_DEBUG;
  },
};

// actions
const actions = {
  async createAccount({ dispatch }, form) {
    let isError = false;
    let message = null;
    let field = null;
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        form.email,
        form.password
      );
      await updateProfile(user, {
        displayName: form.name,
      });
      await _addUserProfile({ fbUserId: user.uid, email: form.email });
      await dispatch("updateUser", user);
      await dispatch("sendVerificationEmail");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        isError = true;
        message = "The email is already registered with another account";
        field = "email";
      } else if (error.code === "auth/invalid-email") {
        isError = true;
        message = "The email is not valid";
        field = "email";
      } else if (error.code === "auth/weak-password") {
        isError = true;
        message = "Choose a strong password";
        field = "password";
      } else {
        throw error;
      }
    }
    return { isError, message, field };
  },
  async googleSignIn({ dispatch }) {
    let isError = false;
    let message = null;
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = result.credential;
      const accessToken = credential.accessToken;
      const user = result.user;
      const dataJson = {
        provider: credential.providerId,
        accessToken,
      };
      await _addUserProfile({
        fbUserId: user.uid,
        email: user.email,
        dataJson,
      });
      await dispatch("updateUser", user);
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        isError = true;
        message = "Account already exists with different credentials";
      } else if (error.code === "auth/popup-blocked") {
        isError = true;
        message = "Popup is blocked by the browser";
      } else if (error.code === "auth/cancelled-popup-request") {
        isError = false;
      } else if (error.code === "auth/popup-closed-by-user") {
        isError = false;
      } else {
        throw error;
      }
    }
    return { isError, message };
  },
  async login({ dispatch }, form) {
    let loginSuccess = true;
    try {
      const { user } = await signInWithEmailAndPassword(
        auth,
        form.email,
        form.password
      );
      await dispatch("updateUser", user);
    } catch (error) {
      const authErrors = [
        "auth/invalid-email",
        "auth/user-disabled",
        "auth/user-not-found",
        "auth/wrong-password",
      ];
      loginSuccess = false;
      if (!authErrors.includes(error.code)) {
        throw error;
      }
    }
    return { loginSuccess };
  },
  async sendVerificationEmail() {
    await sendEmailVerification(auth.currentUser);
  },
  async sendPasswordResetEmail(context, email) {
    let isSuccess = true;
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      const authErrors = ["auth/invalid-email", "auth/user-not-found"];
      isSuccess = false;
      if (!authErrors.includes(error.code)) {
        throw error;
      }
    }
    return { isSuccess };
  },
  async reloadUser({ dispatch }) {
    await reload(auth.currentUser);
    await dispatch("updateUser", auth.currentUser);
  },
  async verifyCode({ dispatch }, code) {
    let isValidCode = true;
    try {
      await applyActionCode(auth, code);
      await dispatch("reloadUser");
    } catch (error) {
      const errorCodes = [
        "auth/expired-action-code",
        "auth/invalid-action-code",
        "auth/user-disabled",
        "auth/user-not-found",
      ];
      isValidCode = false;
      if (!errorCodes.includes(error.code)) {
        throw error;
      }
    }
    return isValidCode;
  },
  async resetPassword(context, { code, password }) {
    let isError = false;
    let message = null;
    try {
      await confirmPasswordReset(auth, code, password);
    } catch (error) {
      const errorCodes = [
        "auth/expired-action-code",
        "auth/invalid-action-code",
        "auth/user-disabled",
        "auth/user-not-found",
      ];
      isError = true;
      if (error.code == "auth/weak-password") {
        message = "Choose a strong password";
      } else if (errorCodes.includes(error.code)) {
        message = "The password reset code is either invalid or has expired";
      } else {
        throw error;
      }
    }
    return { isError, message };
  },
  async changePassword(context, { oldPassword, newPassword }) {
    let isError = false;
    let message = "";
    let field = "";
    const user = auth.currentUser;
    try {
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);
    } catch (error) {
      const errorCodes = [
        "auth/user-mismatch",
        "auth/user-not-found",
        "auth/invalid-credential",
        "auth/invalid-email",
        "auth/wrong-password",
        "auth/invalid-verification-code",
        "auth/invalid-verification-id",
      ];
      isError = true;
      field = "oldPassword";
      message = "The old password is not valid";
      if (!errorCodes.includes(error.code)) {
        throw error;
      }
    }
    if (isError == false) {
      try {
        await updatePassword(user, newPassword);
      } catch (error) {
        const errorCodes = ["auth/weak-password", "auth/requires-recent-login"];
        isError = true;
        field = "newPassword";
        message = "The new password does not meets the criteria";
        if (!errorCodes.includes(error.code)) {
          throw error;
        }
      }
    }
    return { isError, message, field };
  },
  async signOut() {
    await signOut(auth);
  },
  async updateUser({ commit }, fbUser) {
    if (fbUser != null) {
      const profile = await _getUserProfile();
      // the fbId is not yet set in the db when the account is first created
      if (profile.fbId == null) {
        return;
      }
      const { impersonate } = await _canImpersonateUsers();
      commit("SET_USER", {
        fbUser,
        profile,
        impersonate,
      });
    } else {
      commit("RESET_USER");
    }
  },
  async saveDetails({ state, commit }, details) {
    let profile = {
      ...state.profile,
      ...details,
    };
    profile = await _updateUserProfile(profile);
    commit("SET_PROFILE", profile);
  },
  async impersonateOrganization({ commit }, { organizationNameId }) {
    const profile = await _impersonateOrganization({ organizationNameId });
    commit("SET_PROFILE", profile);
  },
};

// mutations
const mutations = {
  SET_USER(state, { fbUser, profile, impersonate }) {
    state.fbUser = fbUser;
    state.fbUserAttributes = {
      userId: fbUser.uid,
      isAuthenticated: true,
      email: fbUser.email,
      emailVerified: fbUser.emailVerified,
      name: fbUser.displayName,
    };
    state.profile = profile;
    state.canImpersonate = impersonate;
  },
  RESET_USER(state) {
    state.fbUser = null;
    state.fbUserAttributes = null;
    state.profile = null;
  },
  SET_PROFILE(state, profile) {
    state.profile = profile;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
