<template>
  <img src="/images/abby-icon.png" />
</template>

<script>
export default {
  name: "AbbyIcon",
};
</script>

<style scoped></style>
