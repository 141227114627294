import { getAllSocialNetworks as _getAllSocialNetworks } from "@/service/socialNetworkService";

const state = () => ({
  socialNetworks: [],
  isInitialized: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  async initSocialNetworks({ state, commit }) {
    if (state.isInitialized) {
      return;
    }
    if (state.loading) {
      return;
    }
    commit("SET_LOADING", true);
    const socialNetworks = await _getAllSocialNetworks();
    commit("SET_SOCIAL_NETWORKS", socialNetworks);
    commit("SET_INITIALIZED");
    commit("SET_LOADING", false);
  },
};

// mutations
const mutations = {
  SET_SOCIAL_NETWORKS: function (state, socialNetworks) {
    state.socialNetworks = socialNetworks;
  },
  SET_INITIALIZED: function (state) {
    state.isInitialized = true;
  },
  SET_LOADING: function (state, loading) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
