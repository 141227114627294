<template>
  <v-navigation-drawer clipped :mini-variant.sync="mini" permanent app>
    <template v-slot:prepend>
      <v-list color="primary darken-1" dark>
        <v-list-item two-line class="px-2">
          <v-list-item-avatar>
            <v-icon dark large>mdi-account-multiple</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-if="canImpersonate">
              <v-autocomplete
                v-model="selectedOrganization"
                :items="organizations"
                outlined
                dense
                hide-no-data
                hide-details
                item-text="name"
                return-object
                @change="switchOrganization"
              ></v-autocomplete>
            </v-list-item-title>
            <v-list-item-title v-else>{{ organizationName }}</v-list-item-title>
            <!-- <v-list-item-subtitle>
              {{ organizationNameId }}
            </v-list-item-subtitle> -->
          </v-list-item-content>
          <v-btn icon @click.stop="mini = !mini" class="ml-2">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </template>
    <v-divider></v-divider>
    <side-bar :items="items"></side-bar>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { getAllOrganizations as _getAllOrganizations } from "@/service/organizationService";
import SideBar from "../commons/SideBar.vue";
export default {
  components: { SideBar },
  name: "PortalSideBar",
  mounted: async function () {
    if (this.canImpersonate) {
      let organizations = await _getAllOrganizations();
      if (!this.accounts.includes("*")) {
        organizations = organizations.filter(
          (organization) =>
            this.originalOrganization.nameId == organization.nameId ||
            this.accounts.includes(organization.nameId)
        );
      }
      this.selectedOrganization = organizations.find(
        (organization) => organization.nameId == this.oid
      );
      this.organizations = organizations.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    }
  },
  data: function () {
    return {
      mini: true,
      selectedOrganization: null,
      organizations: [],
      items: [
        {
          icon: "mdi-calendar-multiple",
          title: "Events",
          target: { name: "Events" },
        },
        {
          icon: "mdi-package-variant",
          title: "Products",
          target: { name: "Products" },
        },
        {
          icon: "mdi-connection",
          title: "Destinations",
          target: { name: "Destinations" },
        },
      ],
    };
  },
  computed: {
    organizationNameId: function () {
      return this.selectedOrganization
        ? this.selectedOrganization.nameId
        : this.oid;
    },
    ...mapState("user", ["canImpersonate"]),
    ...mapGetters("user", [
      "originalOrganization",
      "organizationName",
      "oid",
      "accounts",
    ]),
  },
  methods: {
    switchOrganization: async function () {
      await this.impersonateOrganization({
        organizationNameId: this.selectedOrganization.nameId,
      });
      window.location.reload();
    },
    ...mapActions("user", ["impersonateOrganization"]),
  },
};
</script>

<style scoped></style>
