import { get as _get, post as _post } from "@/service/apiService";

export async function getAllProducts() {
  return await _get("products/get/all");
}

export async function addProduct(productDict) {
  return await _post("products/add", productDict);
}

export async function updateProduct({ id, product }) {
  return await _post(`products/${id}`, product);
}

export async function deleteProduct({ id }) {
  return await _post(`products/${id}/delete`);
}

export async function fetchProduct({ url }) {
  return await _get("products/fetch", { url });
}
