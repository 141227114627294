<template>
  <router-link :to="homeRoute" exact>
    <v-img
      alt="Inlive Logo"
      class="shrink"
      src="@/assets/abby-logo.svg"
      transition="scale-transition"
      :height="height"
      :width="80"
      contain
      v-if="$vuetify.breakpoint.smAndDown && !showNavIcon"
    />
    <v-img
      alt="Inlive Logo"
      class="shrink ml-8"
      src="@/assets/abby-logo.svg"
      transition="scale-transition"
      :height="height"
      :width="80"
      contain
      v-else-if="$vuetify.breakpoint.mdAndUp"
    />
  </router-link>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AppBarLogo",
  props: {
    homeRoute: Object,
  },
  data: function () {
    return {
      height: 70,
    };
  },
  computed: {
    ...mapState("core", ["showNavIcon"]),
  },
};
</script>

<style scoped></style>
