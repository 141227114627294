<template>
  <v-app class="main-container">
    <component :is="layout"></component>
    <v-snackbar v-model="errorSnackbar" color="error" top :timeout="5000">
      {{ errorMessage || "Oops, something went wrong. Please try again later" }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="errorSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="successSnackbar" color="success" top :timeout="5000">
      {{ successMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="successSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="loading" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AppLayout from "@/components/layout/AppLayout.vue";
import WebsiteLayout from "@/components/layout/WebsiteLayout.vue";
import PortalLayout from "@/components/layout/PortalLayout.vue";
import NoSideBarPortalLayout from "@/components/layout/NoSideBarPortalLayout.vue";
import ContentOnlyLayout from "@/components/layout/ContentOnlyLayout.vue";
import PartnerLayout from "@/components/layout/PartnerLayout.vue";
import LayoutLoader from "@/components/layout/LayoutLoader.vue";

export default {
  components: {
    app: AppLayout,
    web: WebsiteLayout,
    portal: PortalLayout,
    "no-sidebar-portal": NoSideBarPortalLayout,
    "content-only": ContentOnlyLayout,
    partner: PartnerLayout,
    loader: LayoutLoader,
  },
  name: "App",
  beforeMount: function () {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  computed: {
    errorSnackbar: {
      get: function () {
        return this.showError;
      },
      set: function (val) {
        this.SET_SHOW_ERROR({ value: val });
      },
    },
    successSnackbar: {
      get: function () {
        return this.showSuccess;
      },
      set: function (val) {
        this.SET_SHOW_SUCCESS({ value: val });
      },
    },
    ...mapState("core", [
      "showError",
      "showSuccess",
      "successMessage",
      "errorMessage",
      "isDirty",
      "layout",
      "loading",
    ]),
  },
  methods: {
    preventNav: function (event) {
      if (!this.isDirty) {
        return;
      }
      event.preventDefault();
      event.returnValue = "";
    },
    ...mapMutations("core", ["SET_SHOW_ERROR", "SET_SHOW_SUCCESS"]),
  },
};
</script>

<style scoped>
.main-container {
  background: none !important;
}
</style>
