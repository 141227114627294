<template>
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.9999 4.83594C24.2202 4.83594 19.5478 6.25329 15.5736 8.90876C11.5995 11.5642 8.50196 15.3385 6.67284 19.7544C4.84372 24.1703 4.36514 29.0294 5.29762 33.7173C6.23009 38.4052 8.53175 42.7113 11.9115 46.091C15.2913 49.4708 19.5974 51.7724 24.2852 52.7049C28.9731 53.6374 33.8322 53.1588 38.2481 51.3297C42.664 49.5006 46.4383 46.4031 49.0938 42.4289C51.7492 38.4547 53.1666 33.7823 53.1666 29.0026C53.1666 25.829 52.5415 22.6865 51.327 19.7544C50.1125 16.8224 48.3324 14.1583 46.0883 11.9142C43.8443 9.67011 41.1801 7.89 38.2481 6.67552C35.3161 5.46103 32.1735 4.83594 28.9999 4.83594ZM28.9999 48.3359C25.1762 48.3359 21.4383 47.2021 18.2589 45.0777C15.0796 42.9533 12.6016 39.9339 11.1383 36.4012C9.67496 32.8684 9.2921 28.9812 10.0381 25.2309C10.7841 21.4806 12.6254 18.0357 15.3292 15.3319C18.033 12.6281 21.4779 10.7867 25.2282 10.0408C28.9785 9.29478 32.8658 9.67764 36.3985 11.1409C39.9312 12.6042 42.9506 15.0822 45.075 18.2616C47.1994 21.4409 48.3333 25.1788 48.3333 29.0026C48.3333 34.1301 46.2964 39.0476 42.6707 42.6733C39.045 46.299 34.1275 48.3359 28.9999 48.3359Z"
      fill="white"
    />
    <path
      d="M29.0001 14.5002C26.7568 14.5002 24.6054 15.3913 23.0191 16.9776C21.4329 18.5638 20.5417 20.7152 20.5417 22.9585C20.5417 23.5994 20.7964 24.2141 21.2496 24.6673C21.7028 25.1206 22.3175 25.3752 22.9584 25.3752C23.5994 25.3752 24.214 25.1206 24.6673 24.6673C25.1205 24.2141 25.3751 23.5994 25.3751 22.9585C25.3751 22.2415 25.5877 21.5407 25.986 20.9446C26.3843 20.3484 26.9505 19.8838 27.6129 19.6094C28.2752 19.3351 29.0041 19.2633 29.7073 19.4031C30.4105 19.543 31.0564 19.8883 31.5633 20.3952C32.0703 20.9022 32.4156 21.5481 32.5554 22.2513C32.6953 22.9545 32.6235 23.6833 32.3491 24.3457C32.0748 25.0081 31.6102 25.5743 31.014 25.9726C30.4179 26.3709 29.717 26.5835 29.0001 26.5835C28.3591 26.5835 27.7445 26.8381 27.2912 27.2913C26.838 27.7445 26.5834 28.3592 26.5834 29.0002V33.8335C26.5834 34.4744 26.838 35.0891 27.2912 35.5423C27.7445 35.9956 28.3591 36.2502 29.0001 36.2502C29.641 36.2502 30.2557 35.9956 30.7089 35.5423C31.1621 35.0891 31.4167 34.4744 31.4167 33.8335V31.0302C33.3697 30.4598 35.0506 29.2028 36.1498 27.4908C37.2489 25.7788 37.6922 23.7272 37.3979 21.7141C37.1036 19.701 36.0915 17.8621 34.5482 16.5364C33.005 15.2107 31.0345 14.4875 29.0001 14.5002Z"
      fill="white"
    />
    <path
      d="M28.9999 43.4974C30.3346 43.4974 31.4166 42.4154 31.4166 41.0807C31.4166 39.746 30.3346 38.6641 28.9999 38.6641C27.6652 38.6641 26.5833 39.746 26.5833 41.0807C26.5833 42.4154 27.6652 43.4974 28.9999 43.4974Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "QuestionMarkCircleOutline",
};
</script>

<style scoped></style>
