import axios from "axios";
import router from "@/router";
import store from "@/store";
import { auth } from "@/firebase";

const baseURL = process.env.VUE_APP_API_SERVER_BASE_URL + "/api/v1";

const apiService = axios.create({
  baseURL: baseURL,
  timeout: 10000,
});

apiService.interceptors.request.use(async (config) => {
  if (auth.currentUser) {
    config.headers.common["Authorization"] = await auth.currentUser.getIdToken(
      false
    );
  }
  return config;
});

function handleError(error) {
  if (error.response) {
    let name = null;
    if (error.response.status == 401) {
      name = "Login";
    } else if (error.response.status == 403) {
      name = "Forbidden";
    } else if (error.response.status == 404) {
      name = "PageNotFound";
    }
    if (name != null && router.currentRoute.name != name) {
      router.push({ name });
    }
  } else {
    // if (process.env.VUE_APP_DEBUG === "true") {
    console.error(error);
    // }
    store.commit("core/SET_SHOW_ERROR", { value: true }, { root: true });
  }
  throw error;
}

export async function get(path, params, responseType) {
  try {
    const config = {};
    if (params) {
      config["params"] = params;
    }
    if (responseType) {
      config["responseType"] = responseType;
    }
    const response = await apiService.get(path, config);
    return response?.data;
  } catch (error) {
    handleError(error);
  }
}

export async function post(path, data, params, timeout = null) {
  try {
    data = data || {};
    const config = {};
    if (params) {
      config["params"] = params;
    }
    if (timeout) {
      config["timeout"] = timeout;
    }
    const response = await apiService.post(path, data, config);
    return response?.data;
  } catch (error) {
    handleError(error);
  }
}

export function getURI(path, params) {
  const config = {
    url: path,
  };
  if (params) {
    config["params"] = params;
  }
  return baseURL + apiService.getUri(config);
}
