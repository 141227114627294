<template>
  <div class="d-flex fill-height">
    <portal-app-bar></portal-app-bar>
    <portal-side-bar></portal-side-bar>
    <v-main class="flex-grow-1">
      <div class="portal-container fill-height">
        <router-view></router-view>
      </div>
    </v-main>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import PortalAppBar from "@/components/portal/PortalAppBar.vue";
import PortalSideBar from "@/components/portal/PortalSideBar.vue";
export default {
  components: { PortalAppBar, PortalSideBar },
  name: "PortalLayout",
  created: function () {
    this.setTheme("ampup");
  },
  methods: {
    ...mapMutations("core", { setTheme: "SET_THEME" }),
  },
};
</script>

<style scoped>
.portal-container {
  background-color: #fff9f5;
}
</style>
