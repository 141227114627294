import { keyBy as _keyBy } from "lodash";
import {
  getAllLiveStreamEvents as _getAllLiveStreamEvents,
  addLiveStreamEvent as _addLiveStreamEvent,
  updateLiveStreamEvent as _updateLiveStreamEvent,
  cancelLiveStreamEvent as _cancelLiveStreamEvent,
  publishLiveStreamEvent as _publishLiveStreamEvent,
  unpublishLiveStreamEvent as _unpublishLiveStreamEvent,
  addLiveStreamProduct as _addLiveStreamProduct,
  updateLiveStreamProduct as _updateLiveStreamProduct,
  deleteLiveStreamProduct as _deleteLiveStreamProduct,
  startLiveStreamEventTest as _startLiveStreamEventTest,
  endLiveStreamEventTest as _endLiveStreamEventTest,
  startLiveStreamEvent as _startLiveStreamEvent,
  endLiveStreamEvent as _endLiveStreamEvent,
  updateDestinations as _updateDestinations,
  addLiveStreamWidget as _addLiveStreamWidget,
  updateLiveStreamWidget as _updateLiveStreamWidget,
  deleteLiveStreamWidget as _deleteLiveStreamWidget,
  updateWidgetsOrder as _updateWidgetsOrder,
  getEventMetadata as _getEventMetadata,
  showEventWidget as _showEventWidget,
  hideEventWidget as _hideEventWidget,
  listenEventMetadata as _listenEventMetadata,
} from "@/service/userEventService";
import Vue from "vue";

const state = () => ({
  events: [],
  eventsMetadataDict: {},
  eventMetadatUnsubscribeFuncDict: {},
  isInitialized: false,
  loading: false,
});

// getters
const getters = {
  eventsUUIDMap: (state) => {
    return state.events ? _keyBy(state.events, "uuid") : {};
  },
};

// actions
const actions = {
  async initEvents({ state, commit }) {
    if (state.isInitialized) {
      return;
    }
    if (state.loading) {
      return;
    }
    commit("SET_LOADING", true);
    const events = await _getAllLiveStreamEvents();
    commit("SET_EVENTS", events);
    commit("SET_INITIALIZED");
    commit("SET_LOADING", false);
  },
  async addEvent({ commit }, eventDict) {
    const event = await _addLiveStreamEvent(eventDict);
    commit("ADD_EVENT", event);
    return event;
  },
  async updateEvent({ commit }, { id, event }) {
    const updatedEvent = await _updateLiveStreamEvent({ id, event });
    commit("UPDATE_EVENT", updatedEvent);
  },
  async updateDestinations({ commit }, { id, destinations }) {
    const updatedEvent = await _updateDestinations({ id, destinations });
    commit("UPDATE_EVENT", updatedEvent);
  },
  async cancelEvent({ commit }, { id }) {
    await _cancelLiveStreamEvent({ id });
    commit("CANCEL_EVENT", id);
  },
  async publishEvent({ commit }, { id }) {
    const updatedEvent = await _publishLiveStreamEvent({ id });
    commit("UPDATE_EVENT", updatedEvent);
  },
  async unpublishEvent({ commit }, { id }) {
    const updatedEvent = await _unpublishLiveStreamEvent({ id });
    commit("UPDATE_EVENT", updatedEvent);
  },
  async startEventTesting({ commit }, { id }) {
    const updatedEvent = await _startLiveStreamEventTest({ id });
    commit("UPDATE_EVENT", updatedEvent);
  },
  async endEventTesting({ commit }, { id }) {
    const updatedEvent = await _endLiveStreamEventTest({ id });
    commit("UPDATE_EVENT", updatedEvent);
  },
  async startEvent({ commit }, { id }) {
    const updatedEvent = await _startLiveStreamEvent({ id });
    commit("UPDATE_EVENT", updatedEvent);
  },
  async endEvent({ commit }, { id }) {
    const updatedEvent = await _endLiveStreamEvent({ id });
    commit("UPDATE_EVENT", updatedEvent);
  },
  async addProduct({ commit }, { eventId, product }) {
    const newProduct = await _addLiveStreamProduct({ eventId, product });
    commit("ADD_PRODUCT", { eventId, product: newProduct });
  },
  async updateProduct({ commit }, { eventId, productId, product }) {
    const updatedProduct = await _updateLiveStreamProduct({
      eventId,
      productId,
      product,
    });
    commit("UPDATE_PRODUCT", { eventId, productId, product: updatedProduct });
  },
  async deleteProduct({ commit }, { eventId, productId }) {
    await _deleteLiveStreamProduct({ eventId, productId });
    commit("DELETE_PRODUCT", { eventId, productId });
  },
  async addWidget({ commit }, { eventId, widgetTemplateId, name, config }) {
    const newWidget = await _addLiveStreamWidget({
      eventId,
      widgetTemplateId,
      name,
      config,
    });
    commit("ADD_WIDGET", { eventId, widget: newWidget });
    return newWidget;
  },
  async updateWidget({ commit }, { eventId, widgetId, name, tags,config }) {
    const updatedWidget = await _updateLiveStreamWidget({
      eventId,
      widgetId,
      name,
      tags,
      config,
    });
    commit("UPDATE_WIDGET", { eventId, widgetId, widget: updatedWidget });
  },
  async deleteWidget({ commit }, { eventId, widgetId }) {
    await _deleteLiveStreamWidget({ eventId, widgetId });
    commit("DELETE_WIDGET", { eventId, widgetId });
  },
  async updateWidgetsOrder({ commit }, { eventId, order }) {
    await _updateWidgetsOrder({ eventId, order });
    commit("UPDATE_EVENT_ORDER", { eventId, order });
  },
  async fetchEventMetadata({ commit }, { eventId }) {
    let metadata = await _getEventMetadata({ eventId });
    if (metadata == null) {
      metadata = {
        visibleWidgets: [],
      };
    }
    commit("ADD_EVENT_METADATA", { eventId, metadata });
  },
  async showEventWidget({ commit }, { eventId, eventWidgetId }) {
    await _showEventWidget({ eventId, eventWidgetId });
    commit("ADD_VISIBLE_WIDGET", { eventId, eventWidgetId });
  },
  async hideEventWidget({ commit }, { eventId, eventWidgetId }) {
    await _hideEventWidget({ eventId, eventWidgetId });
    commit("REMOVE_VISIBLE_WIDGET", { eventId, eventWidgetId });
  },
  async listenEventMetadataChanges({ commit }, { eventId }) {
    const eventMetadataListener = ({ metadata }) => {
      commit("ADD_EVENT_METADATA", { eventId, metadata });
    };
    const eventMetadataUnsubscribeFunc = await _listenEventMetadata({
      eventId,
      listener: eventMetadataListener,
    });
    commit("SET_EVENT_METADATA_UNSUBSCRIBE_FUNC", {
      eventId,
      unsubscribeFunc: eventMetadataUnsubscribeFunc,
    });
  },
  async stopListeningEventMetadataChanges({ state }, { eventId }) {
    const eventMetadataUnsubscribeFunc =
      state.eventMetadatUnsubscribeFuncDict[eventId];
    if (eventMetadataUnsubscribeFunc) {
      eventMetadataUnsubscribeFunc();
    }
  },
};

// mutations
const mutations = {
  SET_EVENTS: function (state, events) {
    state.events = events;
  },
  SET_INITIALIZED: function (state) {
    state.isInitialized = true;
  },
  SET_LOADING: function (state, loading) {
    state.loading = loading;
  },
  ADD_EVENT: function (state, event) {
    state.events.push(event);
  },
  UPDATE_EVENT: function (state, event) {
    const index = state.events.findIndex((x) => x.id == event.id);
    if (index >= 0) {
      state.events.splice(index, 1, event);
    }
  },
  CANCEL_EVENT: function (state, eventId) {
    const events = state.events.filter((event) => event.id != eventId);
    state.events = events;
  },
  UPDATE_EVENT_ORDER: function (state, { eventId, order }) {
    const event = state.events.find((x) => x.id == eventId);
    if (event) {
      const dataJson = event.dataJson || {};
      const updatedDataJson = { widgets: { order } };
      Vue.set(event, "dataJson", { ...dataJson, ...updatedDataJson });
    }
  },
  ADD_PRODUCT: function (state, { eventId, product }) {
    const event = state.events.find((x) => x.id == eventId);
    if (event) {
      event.products.push(product);
    }
  },
  UPDATE_PRODUCT: function (state, { eventId, productId, product }) {
    const event = state.events.find((x) => x.id == eventId);
    if (event) {
      const products = event.products;
      const index = products.findIndex((x) => x.id == productId);
      if (index >= 0) {
        products.splice(index, 1, product);
      }
    }
  },
  DELETE_PRODUCT: function (state, { eventId, productId }) {
    const event = state.events.find((x) => x.id == eventId);
    if (event) {
      const products = event.products.filter(
        (product) => product.id != productId
      );
      event.products = products;
    }
  },
  ADD_WIDGET: function (state, { eventId, widget }) {
    const event = state.events.find((x) => x.id == eventId);
    if (event) {
      event.widgets.push(widget);
    }
  },
  UPDATE_WIDGET: function (state, { eventId, widgetId, widget }) {
    const event = state.events.find((x) => x.id == eventId);
    if (event) {
      const widgets = event.widgets;
      const index = widgets.findIndex((x) => x.id == widgetId);
      if (index >= 0) {
        widgets.splice(index, 1, widget);
      }
    }
  },
  DELETE_WIDGET: function (state, { eventId, widgetId }) {
    const event = state.events.find((x) => x.id == eventId);
    if (event) {
      const widgets = event.widgets.filter((widget) => widget.id != widgetId);
      event.widgets = widgets;
    }
  },
  ADD_EVENT_METADATA: function (state, { eventId, metadata }) {
    Vue.set(state.eventsMetadataDict, eventId, metadata);
  },
  ADD_VISIBLE_WIDGET: function (state, { eventId, eventWidgetId }) {
    const metadata = state.eventsMetadataDict[eventId];
    if (metadata && metadata.visibleWidgets) {
      metadata.visibleWidgets.push(eventWidgetId);
    }
  },
  REMOVE_VISIBLE_WIDGET: function (state, { eventId, eventWidgetId }) {
    const metadata = state.eventsMetadataDict[eventId];
    if (metadata && metadata.visibleWidgets) {
      metadata.visibleWidgets = metadata.visibleWidgets.filter(
        (visibleWidgetId) => visibleWidgetId != eventWidgetId
      );
    }
  },
  SET_EVENT_METADATA_UNSUBSCRIBE_FUNC(state, { eventId, unsubscribeFunc }) {
    Vue.set(state.eventMetadatUnsubscribeFuncDict, eventId, unsubscribeFunc);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
